import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './HeaderNav.scss';
import api from '../../util/api';

import AuthIcon from '../Icons/AuthIcon';
import HelpIcon from '../Icons/HelpIcon';
import ProfileIcon from '../Icons/ProfileIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import MessagesIcon from '../Icons/MessagesIcon';
import SearchIcon from '../Icons/SearchIcon';

import closeComponent from '../../decorators/closeComponent';

import { openSearch, closeSearch } from '../../actions/search';

class HeaderNav extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
      unreadCount: PropTypes.string,
    }),

    openSearch: PropTypes.func,
    closeSearch: PropTypes.func,
  };

  state = {
    isActive: false,
  };

  openNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
    this.props.closeSearch();
  };

  closeComponent = () => {
    this.setState({
      isActive: false,
    });
  };

  disableBodyScroll = () => {
    const { isActive } = this.state;
    const { body } = document;

    if (isActive) {
      body.classList.add('disable--scroll');
    } else {
      body.classList.remove('disable--scroll');
    }
  };

  openSearch = () => {
    const { openSearch } = this.props;

    openSearch();
    this.closeComponent();
  };

  getActivePage = () => {
    switch (this.props.location.pathname.split('/')[1]) {
      case 'authentication':
        return 'Auth';
      case 'registration':
        return 'Auth';
      case 'help':
        return 'Help';
      case 'settings':
        return 'Settings';
      case 'profile':
        return 'Profile';
      case 'photos':
        return 'Profile';
      case 'messages':
        return 'Messages';
      case 'conversation':
        return 'Messages';
      default:
        return '';
    }
  };

  render() {
    const { isLoggedIn, unreadCount, isRedirect, flowUrl } = this.props.auth;
    const { isActive } = this.state;
    this.disableBodyScroll();

    let lineClass = 'nav-button__line';
    if (isActive) lineClass += ' nav-button--active';

    return (
      <div className="nav-container">
        <div className="nav-button__container">
          <button type="button" className="nav-button" onClick={this.openNav}>
            <span className={lineClass} />
            <span className="nav-button__line" />
            <span className="nav-button__line" />
          </button>
        </div>

        {isActive && !isLoggedIn
        && (
          <nav className="user-nav">
            <ul className="user-nav__list">
              <li className="user-nav__item">
                {!isRedirect && (
                  <Link to="/registration" className={(this.getActivePage() === 'Auth') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                    <AuthIcon />
                  </Link>
                )}
                {isRedirect && (
                  <a href={flowUrl} className={(this.getActivePage() === 'Auth') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                    <AuthIcon />
                  </a>
                )}
              </li>
              <li className="user-nav__item">
                <Link to="/help" className={(this.getActivePage() === 'Help') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                  <HelpIcon />
                </Link>
              </li>
            </ul>
          </nav>
        )}

        {isActive && isLoggedIn
        && (
          <nav className="user-nav">
            <ul className="user-nav__list">
              <li className="user-nav__item">
                <Link to="/messages" className={(this.getActivePage() === 'Messages') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                  {(unreadCount !== '0') && <div className="user-nav__new-messages">{unreadCount}</div>}
                  <MessagesIcon className="user-nav__icon" />
                </Link>
              </li>
              <li className="user-nav__item">
                <Link
                  to="/" className="user-nav__link" onClick={this.openSearch}
                >
                  <SearchIcon className="user-nav__icon" />
                </Link>
              </li>
              <li className="user-nav__item">
                <Link to="/profile" className={(this.getActivePage() === 'Profile') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                  <ProfileIcon className="user-nav__icon" />
                </Link>
              </li>
              <li className="user-nav__item">
                <Link to="/settings" className={(this.getActivePage() === 'Settings') ? 'user-nav__link user-nav__link--active' : 'user-nav__link'} onClick={this.closeComponent}>
                  <SettingsIcon className="user-nav__icon" />
                </Link>
              </li>
              <li className="user-nav__item">
                <Link to="/conversation/Moderator" className="user-nav__link" onClick={this.closeComponent}>
                  <HelpIcon className="user-nav__icon" />
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
  openSearch,
  closeSearch,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(closeComponent(HeaderNav)));
