import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import logo from '../../images/ucell-logo.svg';
import agentLogo from '../../images/agent-logo.png';

import './Header.scss';
import HeaderNav from '../HeaderNav/HeaderNav';

import { imgLoadHandler } from '../../actions/userList';
import changeLanguage from '../../actions/language';
import { loadProfileInfo } from '../../actions/profile';
import { loadUserInfo } from '../../actions/user';
import setLanguage from '../../util/setLanguage';
import { loadConversationData, resetConversationPageLanguageSwitch } from '../../actions/conversation';

class Header extends Component {
  static propTypes = {
    user: PropTypes.shape({
      userView: PropTypes.shape({
        name: PropTypes.string,
        age: PropTypes.string,
      }),
    }),

    conversation: PropTypes.shape({
      conversationView: PropTypes.shape({
        name: PropTypes.string,
        age: PropTypes.string,
        iurl: PropTypes.string,
        nick: PropTypes.string,
      }),
    }),

    userList: PropTypes.shape({
      isImgLoaded: PropTypes.bool,
    }),

    imgLoadHandler: PropTypes.func,

    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.languageHandler = debounce(300, this.languageHandler);
  }

  languageHandler = () => {
    const { language } = this.props.language;
    const currentLan = language.language;

    this.props.changeLanguage(currentLan, true);
  };

  numToStr = (n, textForms) => {
    const n1 = (Math.abs(n) % 100) % 10;
    if (n > 10 && n < 20) { return `${n} ${textForms[2]}`; }
    if (n1 > 1 && n1 < 5) { return `${n} ${textForms[1]}`; }
    if (n1 === 1) { return `${n} ${textForms[0]}`; }
    return `${n} ${textForms[2]}`;
  };

  render() {
    const { isUserInfoLoad } = this.props.user;
    const { isConversationLoad } = this.props.conversation;
    const { isEditableFieldsLoad } = this.props.profile;
    const { title, imgLoadHandler } = this.props;
    const { isImgLoaded } = this.props.userList;
    const { language, isLanguageLoad } = this.props.language;
    const currentLan = language.language;

    if (isLanguageLoad) {
      if (window.location.pathname.indexOf('conversation') !== -1 && isConversationLoad) {
        const { currentUserId } = this.props.conversation;

        this.props.loadConversationData(currentUserId);
      }

      if (window.location.pathname.indexOf('profile') !== -1 && isEditableFieldsLoad) {
        this.props.loadProfileInfo();
      }

      if (window.location.pathname.indexOf('user') !== -1 && isUserInfoLoad) {
        const { currentUserId } = this.props.user;

        this.props.loadUserInfo(currentUserId);
      }
    }

    if (title === 'Страница пользователя') {
      const { userView, isUserInfoLoad } = this.props.user;
      const { name, age } = userView;

      if (!isUserInfoLoad) return <div className="header" />;

      return (
        <div className="header">
          <Link to="/" className="header__logo">
            <img src={logo} alt="logo" />
          </Link>
          <h1 className="header__title">
            {name}
            ,&nbsp;
            {age}
          </h1>
          <div className="lang-container">
            <input type="checkbox" className="lang-checkbox" onClick={this.languageHandler} defaultChecked={currentLan === 'uz'} />
            <div className="lang-knobs">
              <span>ru</span>
            </div>
          </div>
          <HeaderNav />
        </div>
      );
    }

    if (title === 'Диалог') {
      const { conversationView, isConversationLoad } = this.props.conversation;

      if (!isConversationLoad) return <div className="header" />;

      const {
        name,
        age,
        iurl,
        nick,
      } = conversationView;
      const linkUrl = `/user/${nick}`;
      const numAge = age ? +age.replace(/\D+/g, '') : '';

      return (
        <div>
          {(name === 'Moderator') && (
            <div className="header">
              <div className="header__logo header__logo--avatar">
                <div className="header__stretcher" />
                <img src={agentLogo} alt="logo" />
              </div>
              <h1 className="header__title">
                {setLanguage(language, 'moderator')}
              </h1>
              <div className="lang-container">
                <input type="checkbox" className="lang-checkbox" onClick={this.languageHandler} defaultChecked={currentLan === 'uz'} />
                <div className="lang-knobs">
                  <span>ru</span>
                </div>
              </div>
              <HeaderNav />
            </div>
          )}

          {(name !== 'Moderator') && (
            <div className="header">
              <Link to={linkUrl} className="header__logo header__logo--avatar">
                <div className="header__stretcher" />
                <img src={iurl} alt="logo" onLoad={imgLoadHandler} />
                {!isImgLoaded && <div className="header__logo header__logo--placeholder" />}
              </Link>
              <h1 className="header__title">
                {name}
                ,&nbsp;
                {(currentLan === 'ru') ? this.numToStr(numAge, ['год', 'года', 'лет']) : this.numToStr(numAge, ['yil', 'yil', 'yil'])}
              </h1>
              <div className="lang-container">
                <input type="checkbox" className="lang-checkbox" onClick={this.languageHandler} defaultChecked={currentLan === 'uz'} />
                <div className="lang-knobs">
                  <span>ru</span>
                </div>
              </div>
              <HeaderNav />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="header">
        <Link to="/" className="header__logo">
          <img src={logo} alt="logo" />
        </Link>
        <h1 className="header__title header__title--uppercase">{title}</h1>
        <div className="lang-container">
          <input type="checkbox" className="lang-checkbox" onClick={this.languageHandler} defaultChecked={currentLan === 'uz'} />
          <div className="lang-knobs">
            <span>ru</span>
          </div>
        </div>
        <HeaderNav />
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  user,
  conversation,
  userList,
  language,
  profile,
}) => ({
  auth,
  user,
  conversation,
  userList,
  language,
  profile,
});

const mapDispatchToProps = {
  imgLoadHandler,
  changeLanguage,
  loadProfileInfo,
  loadUserInfo,
  loadConversationData,
  resetConversationPageLanguageSwitch,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
