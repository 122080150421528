/*eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';

import 'emoji-mart/css/emoji-mart.css';
import 'leaflet/dist/leaflet.css';
import './Conversation.scss';
import '../Popup/popup.scss';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup'
import ConversationMsgList from '../ConversationMsgList';
import ConversationInput from '../ConversationInput';

import loaderGif from '../../images/loader.gif';

import {
  loadConversationData,
  resetConversationPage,
  loadMore,
  checkIsMessage,
  streamListener,
  getEvtSource,
  scrollToBottom,
  cancelScrollToBottom,
} from '../../actions/conversation';

import setLanguage from '../../util/setLanguage';

class Conversation extends Component {
  // static propTypes = {
  //   auth: PropTypes.shape({
  //     isLoggedIn: PropTypes.bool,
  //   }),
  //
  //   conversation: PropTypes.shape({
  //     isConversationLoad: PropTypes.bool,
  //     hideLoadButton: PropTypes.bool,
  //     isNewMessages: PropTypes.bool,
  //     isMoreMessagesLoad: PropTypes.bool,
  //     isScroll: PropTypes.bool,
  //   }),
  //
  //   loadConversationData: PropTypes.func,
  //   resetConversationPage: PropTypes.func,
  //   loadMore: PropTypes.func,
  //
  //   match: PropTypes.shape({
  //     params: PropTypes.shape({
  //       uid: PropTypes.string,
  //     }),
  //   }),
  // };
  constructor() {
    super();
    this.msgContainer = React.createRef();
  }

  componentDidMount() {
    this.props.loadConversationData(this.props.match.params.uid);

    document.addEventListener('mousedown', this.handleClick, false);

    const img = new Image();
    img.src = 'https://unpkg.com/emoji-datasource-google@4.0.4/img/google/sheets-256/32.png';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isLoggedIn } = this.props.auth;
    const { isScroll, isStream, currentUserId } = this.props;
    const eventSource = getEvtSource();

    if (isStream) {
      eventSource.addEventListener(currentUserId, this.props.streamListener)
    }

    if (!isLoggedIn) return;

    if (isScroll) {
      this.scrollToBottom();
      this.props.cancelScrollToBottom();
    }
    if (prevProps.match.params.uid !== this.props.match.params.uid) {
      this.props.loadConversationData(this.props.match.params.uid);
    }
  }

  componentWillUnmount() {
    const { timeoutId, currentUserId } = this.props;
    const eventSource = getEvtSource();

    document.removeEventListener('mousedown', this.handleClick, false);

    if (eventSource) {
      eventSource.removeEventListener(currentUserId, this.props.streamListener);
    }

    clearTimeout(timeoutId);
    this.props.resetConversationPage();
  }

  scrollToBottom = () => {
    if (this.msgContainer.current) {
      const container = this.msgContainer.current;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  };

  loadMoreHandler = () => {
    this.props.loadMore();
  };

  render() {
    const { language } = this.props.language;
    const { isLoggedIn } = this.props.auth;
    const {
      isConversationLoad, hideLoadButton, isMoreMessagesLoad,
    } = this.props;
    const { isPopupOpen } = this.props.popup;
    const isModerator = (this.props.match.params.uid === 'Moderator');

    if (!isLoggedIn) return <Redirect to="/" />;

    return (
      <div className="conversation">

        <div className="conversation__container" ref={this.msgContainer}>
          {!isConversationLoad && <Loader />}

          {isConversationLoad && <InfiniteScroll
            pageStart={0}
            loadMore={this.props.loadMore}
            hasMore={!hideLoadButton}
            isReverse={true}
            loader={<img className="conversation__loader" src={loaderGif} alt="загрузка..." key={0.1} />}
            useWindow={false}
            threshold={10}
          >
            <div>
              <ConversationMsgList isModerator={isModerator} />
            </div>

          </InfiniteScroll>}

        </div>

        <ConversationInput isModerator={isModerator} />

        {/*<div className="screen-logger">{this.props.conversation.logmsg}</div>*/}
        {isPopupOpen && <Popup />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isConversationLoad, hideLoadButton, isMoreMessagesLoad, timeoutId, isScroll, isStream, currentUserId } = state.conversation;

  return {
    isConversationLoad: isConversationLoad,
    hideLoadButton: hideLoadButton,
    isMoreMessagesLoad: isMoreMessagesLoad,
    timeoutId: timeoutId,
    isScroll: isScroll,
    isStream: isStream,
    currentUserId: currentUserId,
    auth: state.auth,
    language: state.language,
    popup: state.popup,
  }
};

const mapDispatchToProps = {
  loadConversationData,
  resetConversationPage,
  loadMore,
  checkIsMessage,
  streamListener,
  scrollToBottom,
  cancelScrollToBottom,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Conversation));
