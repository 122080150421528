import React from 'react';

function MessageIcon() {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
      <image
        id="image0" width="100%" height="100%" x="0" y="0" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAABHGlDQ1BpY2MAACiRY2BgMnB0cXJl
EmBgyM0rKQpyd1KIiIxSYD/PwMbAzAAGicnFBY4BAT4gdl5+XioDBvh2jYERRF/WBZmFKY8XcCUX
FJUA6T9AbJSSWpzMwMBoAGRnl5cUAMUZ5wDZIknZYPYGELsoJMgZyD4CZPOlQ9hXQOwkCPsJiF0E
9ASQ/QWkPh3MZuIAmwNhy4DYJakVIHsZnPMLKosy0zNKFAwtLS0VHFPyk1IVgiuLS1JzixU885Lz
iwryixJLUlOAaiHuAwNBiEJQiGkANVpokuhvggAUDxDW50Bw+DKKnUGIIUByaVEZlMnIZEyYjzBj
jgQDg/9SBgaWPwgxk14GhgU6DAz8UxFiaoYMDAL6DAz75gAAwMZP/aCJEEUAAAAgY0hSTQAAeiYA
AICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAs1QTFRF////iQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZiQCZ
iQCZiQCZiQCZiQCZ////Ul6ySAAAAO10Uk5TAAAJmMlilvi/D3P9Izd0gCwr/mgSw/WMaZEGIl8g
AVFmDRWLjhcoR/N41RjXwa3yRUj3vhnmJvZJbBrYdgzv+UCFG9mDQUv0Phzagk09gdvcfk88t7jx
O3ylU6TwOtQUVPo40qcDH9DTqBPPVfs2FqpX/DSP1ooKvZCNWe4zibtb7TKHCM2wY1gx6WTsMMIt
6uTeQsgem0NE4C9hETnR3R3rYLFGLl2yByTfegK8IU6ViJeZwAt7P0rhrCUEqcaeyhBQVqAOs66T
NW6vZ2oFtH95oVyGn2XMcsuitSfnxKa5zuKddbYpnCpaxaOE17On7wAAAAFiS0dEAIgFHUgAAAAJ
cEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfjCAIJDy6S4mpJAAAHc0lEQVRo3u2Z+19URRTAdxBD
JFI2wFx1UR6CgiKKQqLhAx88VFyfoSCogG9gRalQEkTNB6YQCqaiaJqCAWJavhJLzUrT3maalfae
/6E5M3fvg527e29+PvWpz55f7sycM/O9d+7MOWfuNRhc4hKXuMQlfEH/gPy7ELcO7h11jfSERydP
vZDOGHs9qYPhjTF+Si+kC+nUVQfEx4jx03ohvn7+3XRN1zPu3U16ID169mIFc0DvPhrGDwwKDhGK
fXn2PEioPw5jr6Mfxv3DnTLCIzAewG5qII4cpAkSNZi8jp60OISUIpxRogkDD6XFYaQUE6oBEutB
LP3ZkzyLgRLtkDE8DoxG0PLI57gUO0hsPDBGCbXRMEDccAeMMWPBJEGojfMnlcFRTiDjgTFholhP
gCHGjlFlJCaBQbJYpxSPWIeQFGCETZK1TIZBktQoiVNAPVnWMopDMThhsF2JkwK5jJBUUE5VtE2c
QJrix6tCLMCInNZupKkw0HQuZIY9A6FJYUBJUYEEAGPmLLuhZsNQwziMvqCYbddsRzE4ZyD0PFGk
cdrTSPscTvu0SKBYOJA0YMxN5/QJ7E80sRxFKGnPmMdRzJoJlAA7SGYWMObzGAtgVhZm22tyQJHL
WxPpCopBwfDmMRZhKovtKEuWUsUCLmUuUNIUkGXLgbGCY52XgQXJ76EUn6WCgkuZD5SsTBmkwEpa
nlvJsQ2B94ELO2EVoaCMPE7PFUBZnilC1Bnhq2CQ1ajoBT7jRfNLcOkfokpZJkCKgbFmrSqjhJSK
XuYxCs0IBalSVoJPthZQyDpglJZxrGikwOtpuXxqfv4GqBo35guycBPVlEDrKl7UWbsGKK8QCJ2r
0s0cGxYpRot1ny3A2GpnV2IXdcqz5ZQ0A9omY1Rsd5d2PIsUEuPVHcDYxLmb9e0ohdadPqxUBpQt
BlQJQaqKtb2GcarISJJHI4HhxU/4lLGtmlR2sWIVOP5KA9odA5Qa2kYmvVawZNFIihR7gGF9HSF1
ii227fW3TUANMGLGkRcfSik0ydi3v07wUQdSlYyKg8CoR2qSII9tuw91K4LrYWD4H6ZL2FaWSXWd
MlJUwJuzshTG17IsqgYk/Q2z1INF0ET5IKG2OTJITyVLMqorlZFiM2UcYfO2RdonR9+U+tAImnqA
wxDcSo0yychpxzhWCox10ryJMqNIGpRG0DqRQtM3tqIMslVgo/Q6DuYNYu/GXIlBn0kmJ2QUGkHr
qlmFpW8stbK5elmS8VYtGPeTOjcBo5gWy+gzNbe0nGxtbfUDu1NvS4anoaEyhzJo+nYGKSDoDFDi
3yGMd9sxULM4d2wHrxPaTWfB0q+PZNkADcdz2qdvUvhlqUzTOcoYIV8lh0jDHolRLCrOXwDbi7ID
Vj9K6WFRplayRIImGUyUR6b3SAvcvehVRcmeA9aX2sQGt1XiGLL0TZ4STbNRIisUkK2kacfl8GKI
D9b3FarsD8B+g69Q3esnMuTpmyK5m2VbOtsUTvmKtDNoDJJL4y4aH1nKEr1fNNwmT3uUaWpi8NKI
q1Y7SpNV6CrEbLmYT4JiEQTgYXTpX5u4OCk3WLH1OYcgThCzlFLG0N321sj8oRC0roMLxa2c1Il3
nOOE47aPPu509pORiCcszMcdCVO6VCcQW2IR3bulsH4eci4ltjdhvcHV84/YlOIP+R6OSb7pnDKa
MTwy+WqVczxN9gT5NFt99MAcdr0FhvFVKlZqHwsyrRKliyqjIMsYzGIKOBTjOZ2QY0Zw5AEFl2De
1D7MINCeZJSBpHhDxUwNcpv02UiuJndSKFCDfEYXLaV8Tkpf6IR8SfpEQeErbH9gE6Xta6DsAgoE
+W90QsAn0a3XG4bZcTSFb9Z2B9TfLjFbIFLs0wmBF3mX3KHpjvD2r/F3oidMJzbSjbigUSfkOy/S
a0h9/R1xjd1VrOTGqjI5ha7g+UgnhL4UQbxqYS6wIrGbg42r2bLyrBX8rtoucQDxvGRjbI9GVxaT
a4ZMa86SfGEL+KCLDQ78j/rXVLdmtuuzIMUtv0dK8mHug+p7+qkOvHAxciSOPtkO3wQ+KY6WH8BK
HlQu6vJ+oB7HjSSCEJTX/m0IEXLqj9lLrjd30qeamSB6yzYaaSNulUDYnGJ+HMh0WLxu6Hx32wsa
IiZzRQ+kpXEZPQ4EPmzgNad+lMb7SdSZBgpNxtuOB3EGMV8VRz8Y9BAWnJFkYN7J9DxmokdKHGdB
jwdBRY9sDLLIsuHeV6Of44XT0C+kun6P2RnD+Q8Bc/F92PzsC+B18P+oHrNznQkyuTKnCG1/HdyS
bUklfJJ9hPLoKXhhA6zig9kaBtD0ayOFjJYLixcOOs3CWZtJZy39NUH6gJM9MalrEMRkcPq/2k5C
17Q8iMafNMnSEqYQ1JEWJ9zSxNAIMZ2QIPTjYhQpDIi9oqmz5t9Nbh2Yt4SFFv8b8v2dXB9qROj4
p3Xu9IVDhen0Eya+B2dOHf+J9P44o58wqXTX3kn33znvoYzxh0l7H/2/AOf9GeFROr1JT5f/0c9M
l7jEJS75D8pfqg+4DQ1/GyAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDgtMDJUMDk6MTU6NDYr
MDM6MDAPDq+rAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTA4LTAyVDA5OjE1OjQ2KzAzOjAwflMX
FwAAADd0RVh0aWNjOmNvcHlyaWdodABDb3B5cmlnaHQgMTk5OSBBZG9iZSBTeXN0ZW1zIEluY29y
cG9yYXRlZDFs/20AAAAgdEVYdGljYzpkZXNjcmlwdGlvbgBBZG9iZSBSR0IgKDE5OTgpsLrq9gAA
ACF0RVh0aWNjOm1hbnVmYWN0dXJlcgBBZG9iZSBSR0IgKDE5OTgpgpCF0gAAABp0RVh0aWNjOm1v
ZGVsAEFkb2JlIFJHQiAoMTk5OCl0G2zQAAAAAElFTkSuQmCC"
      />
    </svg>
  );
}

export default MessageIcon;
