const host = ``;
const base = `${host}/android`;

export default {
  host,
  stream: `${host}/events/v1/stream`,
  access: `${host}/events/v1/access`,
  users: `${base}/search/ByLiked?page`,
  login: `${base}/auth/login`,
  logout: `${base}/auth/logout`,
  phoneInput: `${base}/auth/PhoneInput`,
  registration: `${base}/auth/registration`,
  recovery: `${base}/auth/Recovery`,
  help: `${base}/feedback/send`,
  captcha: `${base}/feedback/GetCaptcha?transparent=1`,
  ownInfo: `${base}/ownProfile/viewShort`,
  evtStatus: `${base}/ownProfile/EventsStatus`,
  ownEdit: `${base}/ownProfile/edit`,
  ownView: `${base}/ownProfile/view`,
  editableFields: `${base}/ownProfile/EditableFieldsAndPossibleValues`,
  dataSave: `${base}/ownProfile/dataSave`,
  cityList: `${base}/search/citylist`,
  viewShort: `${base}/ownProfile/viewShort`,
  ownPhotoList: `${base}/ownPhoto/index?without_default_img=1`,
  savePhoto: `${base}/ownPhoto/SetMain?photoid=`,
  deletePhoto: `${base}/ownPhoto/delete?photoid=`,
  addPhoto: `${base}/ownPhoto/addAllPhotoWeb`,
  messages: `${base}/message`,
  userView: `${base}/profile/view?nick=`,
  userPhotoList: `${base}/profile/photoList?nick=`,
  sendLike: `${base}/profile/PhotoLike?`,
  addBlackList: `${base}/blacklist/add?nick=`,
  conversation: `${base}/message/conversation?nick=`,
  postMessage: `${base}/message/PostAndRead`,
  postVoice: `${base}/message/sendVoice`,
  postPhoto: `${base}/message/sendPhoto`,
  postLocation: `${base}/message/sendLocation`,
  deleteMessages: `${base}/message/deleteMessages`,
  editMessage: `${base}/message/editMessageText`,
  deleteProfile: `${base}/ownProfile/delete?confirm=YES`,
  search: `${base}/search/perform`,
  setLang: `${base}/service/language/set/ucell_uz_`,
  ofertaRu: `${base}/service/weboferta?lang=1`,
  ofertaUz: `${base}/service/weboferta?lang=2`,
  getCaptchaKey: `${base}/service/config`,
};
