import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import InputMask from 'react-input-mask';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';

import './Reg.scss';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';
import SimilarCityList from '../SimilarCityList';

import {
  sendPhone,
  inputChange,
  sendPass,
  openUnsubPopup,
  loadPhoto,
  submitRegData,
  selectChange,
} from '../../actions/reg';

import {
  loadCityList,
  cityChange,
  openCityList,
} from '../../actions/profile';

import setLanguage from '../../util/setLanguage';
import generateOptions from '../ProfileDateOptions';
import generateData from '../../util/generateData';
import { AGE_FROM, AGE_TO } from '../../constants';

class Reg extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    reg: PropTypes.shape({
      isPhotoLoad: PropTypes.bool,
      regData: PropTypes.shape(),
      isPhoneSend: PropTypes.bool,
      isRegLoginSend: PropTypes.bool,
      isRegRequestLoad: PropTypes.bool,
      isReg: PropTypes.bool,
      isSub: PropTypes.bool,
      isRegDataSend: PropTypes.bool,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    profile: PropTypes.shape({
      isCityListOpen: PropTypes.bool,
      cityList: PropTypes.array,
      isCityIdChange: PropTypes.bool,
      cityName: PropTypes.string,
    }),

    sendPhone: PropTypes.func,
    inputChange: PropTypes.func,
    sendPass: PropTypes.func,
    openUnsubPopup: PropTypes.func,
    loadCityList: PropTypes.func,
    cityChange: PropTypes.func,
    openCityList: PropTypes.func,
    loadPhoto: PropTypes.func,
    submitRegData: PropTypes.func,
    selectChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.callCityChange = debounce(300, this.callCityChange);
  }

  componentDidMount() {
    this.props.loadCityList();
  }

  renderRegFirst = () => {
    const { sendPhone, inputChange } = this.props;

    return (
      <div className="reg">
        <label className="reg__label" htmlFor="phone">
          Введите Ваш номер телефона МТС
        </label>
        <div className="reg__phonebox">
          <InputMask
            pattern="^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$"
            onChange={inputChange}
            className="reg__input"
            data-name="phone"
            mask="(999) 999999999"
            placeholder="(998) XXXXXXXXX"
            maskChar=""
            id="phone"
          />
        </div>
        <button type="button" onClick={sendPhone} className="reg__button button button--red">Отправить</button>
      </div>
    );
  };

  renderRegSecond = () => {
    const { sendPass, inputChange } = this.props;
    const { isPopupOpen } = this.props.popup;

    return (
      <div className="reg">
        <label className="reg__label" htmlFor="pass">
          Введите полученный по СМС пароль
        </label>
        <input
          type="password"
          id="pass"
          className="reg__input reg__input-pass"
          data-name="pass"
          onChange={inputChange}
        />
        <button type="submit" onClick={sendPass} className="reg__button button button--red">Отправить</button>
        {isPopupOpen && <Popup />}
      </div>
    );
  };

  showPopup = () => {
    this.props.openUnsubPopup();
  };

  cityChangeHandler(evt) {
    const { value } = evt.target;
    const { id } = evt.target.dataset;
    this.callCityChange(value, id);
    this.props.openCityList();
  }

  callCityChange(value, id) {
    this.props.cityChange(value, id);
    this.props.loadCityList();
  }

  renderRegThird = () => {
    const {
      isCityListOpen,
      cityList,
      isCityIdChange,
    } = this.props.profile;

    const { language } = this.props.language;

    const { cityName } = this.props.profile;
    const { isPhotoLoad, regData } = this.props.reg;

    const { name, photo } = regData;
    const city = cityName;

    const isDisabled = ((name === undefined) || (photo === undefined) || (city === ''));

    const {
      loadPhoto,
      inputChange,
      submitRegData,
      selectChange,
    } = this.props;
    const { isPopupOpen } = this.props.popup;

    const { dates, months } = generateData();
    const years = generateData()
      .years(AGE_FROM, AGE_TO);

    const datesOptions = generateOptions(dates, 'birthday_day');
    const monthsOptions = generateOptions(months.map(elem => setLanguage(language, elem)), 'birthday_month');
    const yearsOptions = generateOptions(years, 'birthday_year');

    return (
      <div className="reg">
        <form className="reg-form" method="post">

          <fieldset className="reg-form__fieldset">
            <h2 className="reg-form__title">
              {setLanguage(language, 'reg_page_title')}
            </h2>
            <label className="reg-form__label" htmlFor="name">
              {setLanguage(language, 'reg_page_name_field')}
            </label>
            <input className="reg-form__input" type="text" name="name" required data-name="name" onChange={inputChange} />

            <label className="reg-form__label" htmlFor="sex">
              {setLanguage(language, 'reg_page_sex_field')}
            </label>
            <div className="reg-form__checkbox-container">
              <label className="reg-form__check-label" htmlFor="men">
                <input type="radio" name="sex" id="men" defaultChecked />
                <span className="reg-form__checkbox" />
                {setLanguage(language, 'reg_page_sex_men')}
              </label>
              <label className="reg-form__check-label" htmlFor="woman">
                <input type="radio" name="sex" id="woman" />
                <span className="reg-form__checkbox" />
                {setLanguage(language, 'reg_page_sex_woman')}
              </label>
            </div>

            <label className="reg-form__label" htmlFor="age">
              {setLanguage(language, 'reg_page_birth_field')}
            </label>
            <div className="reg-form__age-box">
              <div className="select__wrapper">
                <select
                  className="reg-form__select" name="age" defaultValue="1"
                  data-name="birthday_day" onChange={selectChange}
                >
                  {datesOptions}
                </select>
              </div>
              <div className="select__wrapper">
                <select
                  className="reg-form__select" defaultValue="Января"
                  data-name="birthday_month" onChange={selectChange}
                >
                  {monthsOptions}
                </select>
              </div>
              <div className="select__wrapper">
                <select
                  className="reg-form__select" defaultValue="1990"
                  data-name="birthday_year" onChange={selectChange}
                >
                  {yearsOptions}
                </select>
              </div>
            </div>

            <label className="reg-form__label" htmlFor="city">
              {setLanguage(language, 'reg_page_city_field')}
            </label>
            <div className="reg-form__wrapper">
              <input
                className="reg-form__input city"
                type="text"
                autoComplete="off"
                spellCheck="off"
                name="city"
                data-id="11"
                placeholder={setLanguage(language, 'reg_page_city_placeholder')}
                onChange={this.cityChangeHandler.bind(this)}
                required
              />
              {isCityListOpen && (
                <SimilarCityList cityList={cityList} />
              )}
              {!isCityIdChange && (
                <p className="reg-form__error">{setLanguage(language, 'reg_page_city_error')}</p>
              )}

              {!isPhotoLoad && (
                <div>
                  <p className="reg-form__label--small">{setLanguage(language, 'reg_page_photo_hint')}</p>
                  <label className="reg__button reg__button--file button button--red" htmlFor="file">
                    <input className="photos__input" type="file" name="file" id="file" onChange={loadPhoto} accept="image/*" />
                    {setLanguage(language, 'reg_page_photo_button')}
                  </label>
                  <p className="reg-form__label--bottom">
                    {setLanguage(language, 'field_required')}
                  </p>
                </div>
              )}
              <button type="button" className="reg__button button button--red" onClick={submitRegData} disabled={isDisabled}>
                {setLanguage(language, 'reg_page_save_button')}
              </button>

            </div>
          </fieldset>
        </form>
        {isPopupOpen && <Popup />}
      </div>
    );
  };

  render() {
    const {
      isPhoneSend,
      isRegLoginSend,
      isRegRequestLoad,
      isReg,
      isSub,
      isRegDataSend,
    } = this.props.reg;

    const { isLoggedIn, notFullReg } = this.props.auth;

    if (isLoggedIn && !notFullReg) {
      window.location = '/muloqot';
      // return <Redirect to="/"/>;
    }

    if (!isSub) {
      this.showPopup();
    }

    if (isReg) {
      return (
        <Redirect
          to={{
            pathname: '/authentication',
            state: { referrer: isReg },
          }}
        />
      );
    }

    if (!isRegRequestLoad) return <Loader />;

    if (isRegDataSend) window.location.href = '/muloqot';
    // if (isRegDataSend) return <div />;

    if (isRegLoginSend) return this.renderRegThird();

    if (isPhoneSend) return this.renderRegSecond();

    return this.renderRegFirst();
  }
}

const mapStateToProps = ({
  auth,
  reg,
  popup,
  profile,
  language,
}) => ({
  auth,
  reg,
  popup,
  profile,
  language,
});

const mapDispatchToProps = {
  sendPhone,
  inputChange,
  sendPass,
  openUnsubPopup,
  loadCityList,
  cityChange,
  openCityList,
  loadPhoto,
  submitRegData,
  selectChange,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reg));
