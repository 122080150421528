import Axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
import Cookies from 'js-cookie';
import { getEmojiDataFromNative } from 'emoji-mart';
import {
  BASE_NAME,
  SUCCESS,
  FAIL,
  START_CONVERSATION_LOAD,
  LOAD_CONVERSATION_VIEW,
  SET_CONVERSATION_USER_ID,
  RESET_CONVERSATION_PAGE,
  LOAD_MORE_MESSAGES,
  START_LOAD_MESSAGES,
  CONVERSATION_INPUT_CHANGE,
  START_SEND_MESSAGE,
  SEND_MESSAGE,
  ADD_EMOJI,
  CHANGE_EMOJI_FIELD_STATE,
  RESET_CONVERSATION_PAGE_LANGUAGE,
  START_RECORDING,
  STOP_RECORDING,
  POPUP_OPEN,
  SCREEN_LOGGER,
  FOCUS_INPUT,
  BLUR_INPUT,
  SET_CARET_POSITION,
  CHECK_IS_MESSAGE,
  OPEN_STREAM,
  GET_MESSAGE_FROM_STREAM,
  SET_LOCATION_TYPE,
  SET_TEXT_TYPE,
  SET_PHOTO_TYPE,
  SET_LOCATION_COORDS,
  ADD_CHAT_PHOTO,
  FILE_TYPES,
  MAX_SIZE,
  MIN_SIZE,
  REMOVE_CHAT_PHOTO,
  SCROLL_TO_BOTTOM,
  CANCEL_SCROLL_TO_BOTTOM,
  REMOVE_VOICE,
  OPEN_MSG_MENU,
  CLOSE_MSG_MENU,
  CHECK_MSG_ON_SELECT_LIST,
  REMOVE_MSG,
  EDIT_MSG,
  CANCEL_EDIT_MSG,
  OPEN_MSG_POPUP,
  CLOSE_MSG_POPUP,
  REPLY_MSG,
  CANCEL_REPLY_MSG,
  MAX_LENGTH_MESSAGE,
} from '../constants';

import opacityImg from '../images/opacity.png';
import { api } from '../util';
import { utf8toutf16 } from '../util/smileEncoder';
import { checkEvtStatus } from './auth';

const replaceEmojis = (string, emojiNodeList) =>
  // нативные коды эмоджи записаны в alt атрибут img
  _.reduce(emojiNodeList, (init, el) => {
    if (utf8toutf16(el.alt).toUpperCase() === '2764 FE0F') {
      // eslint-disable-next-line
      return init.replace(/<[^>]+?>/im, '$#2764#$');
    }

    if (utf8toutf16(el.alt).toUpperCase() === '263A FE0F ') {
      // eslint-disable-next-line
      return init.replace(/<[^>]+?>/im, '$#263A#$');
    }

    // eslint-disable-next-line
    return init.replace(/<[^>]+?>/im, ` $#${utf8toutf16(el.alt).toUpperCase()}#$ `);
  }, string);
const unifiedEmojiToNative = (unified) => {
  if (unified.length <= 5) {
    return String.fromCodePoint(`0x${unified}`);
  }
  const sym = unified.split('-');
  const codesArray = [];
  _.forEach(sym, el => codesArray.push(`0x${el}`));
  return String.fromCodePoint(...codesArray);
};

let evtSource;

export function openStream() {
  return (dispatch, getState) => {
    const sid = Cookies.get('SID');
    const { access, stream } = api;

    if (sid) {
      Axios.get(`${access}/${sid}`)
        // Axios.get(`/v1/access/${sid}`) //для локалки
        .then((res) => {
          evtSource = new EventSource(stream, { withCredentials: true });
          // evtSource = new EventSource(`/v1/stream`, { withCredentials: true }); //для локалки

          evtSource.onmessage = (evt) => {

          };

          evtSource.onerror = (err) => {
            console.log('---', err);
          };

          dispatch({
            type: OPEN_STREAM,
          });
        })
        .catch((err) => {
          console.error('---', err);
        });
    }
  };
}

export function getEvtSource() {
  return evtSource;
}

export function streamListener(evt) {
  const data = JSON.parse(evt.data);

  if (data.type === 'deleted') {
    const removedMsg = new Set();
    removedMsg.add(data.id);
    return (dispatch) => {
      dispatch({
        type: REMOVE_MSG,
        payload: removedMsg,
      });
    };
  }

  return (dispatch) => {
    dispatch({
      type: GET_MESSAGE_FROM_STREAM,
      payload: JSON.parse(evt.data),
      nick: evt.type,
    });
  };
}

export function loadConversationData(uid) {
  return (dispatch) => {
    dispatch({
      type: START_CONVERSATION_LOAD,
    });

    Axios.get(`${api.conversation}${uid}&page=0`, { withCredentials: true })
      .then((res) => {
        // dispatch(checkEvtStatus());
        if (res.status >= 400) throw new Error(res);
        return res.data;
      })
      .then((data) => {
        dispatch({
          type: RESET_CONVERSATION_PAGE,
        });
        dispatch({
          type: LOAD_CONVERSATION_VIEW + SUCCESS,
          payload: data,
        });
        dispatch({
          type: SET_CONVERSATION_USER_ID,
          payload: uid,
        });
      })
      .catch((error) => {
        console.error('---', error);
        dispatch({
          type: LOAD_CONVERSATION_VIEW + FAIL,
          payload: { error },
        });
      });
  };
}

export function loadMore() {
  return (dispatch, getState) => {
    const { conversation: { currentUserId, currentPage, isMoreMessagesLoad } } = getState();

    if (!isMoreMessagesLoad || !currentUserId) return;

    const url = `${api.conversation}${currentUserId}&page=${currentPage}`;

    dispatch({
      type: START_LOAD_MESSAGES,
    });

    Axios
      .get(url, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: LOAD_MORE_MESSAGES + SUCCESS,
          payload: {
            data,
            currentPage: currentPage + 1,
          },
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: LOAD_MORE_MESSAGES + FAIL,
          payload: err,
        });
      });
  };
}

export function openMsgMenu() {
  return (dispatch, getState) => {
    const { conversation: { isMsgMenuOpen } } = getState();

    if (!isMsgMenuOpen) {
      dispatch({
        type: OPEN_MSG_MENU,
      });
    }
  };
}

export function closeMsgMenu() {
  return (dispatch, getState) => {
    const { conversation: { isMsgMenuOpen } } = getState();

    if (isMsgMenuOpen) {
      dispatch({
        type: CLOSE_MSG_MENU,
      });
    }
  };
}

export function openMsgPopup() {
  return (dispatch, getState) => {
    const { conversation: { isMsgPopupOpen } } = getState();

    if (!isMsgPopupOpen) {
      dispatch({
        type: OPEN_MSG_POPUP,
      });
    }
  };
}

export function closeMsgPopup() {
  return (dispatch, getState) => {
    const { conversation: { isMsgPopupOpen } } = getState();

    if (isMsgPopupOpen) {
      dispatch({
        type: CLOSE_MSG_POPUP,
      });
    }
  };
}

export function checkMsgOnSelectList(id) {
  return (dispatch) => {
    dispatch({
      type: CHECK_MSG_ON_SELECT_LIST,
      payload: id,
    });
  };
}

export function removeMsg() {
  return (dispatch, getState) => {
    const { conversation: { selectedMsg, currentUserId } } = getState();

    const data = new FormData();
    data.append('thirdPerson_nick', `${currentUserId}`);
    data.append('ids', `${[...selectedMsg].toString()}`);

    const sendObj = data;

    dispatch(closeMsgPopup());
    Axios
      .post(api.deleteMessages, sendObj, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: REMOVE_MSG,
          payload: selectedMsg,
        });
      })
      .catch((err) => {
        console.error('---', err);
      });
  };
}

export function editMsg(msgNode, emojiData) {
  return (dispatch, getState) => {
    const { childNodes } = msgNode;
    const { conversation: { fullMessages, selectedMsg } } = getState();
    let msgToEdit = fullMessages.find(el => +el.id === +[...selectedMsg][0]).msg;

    const arrayOfInnerSpans = _.reduce(childNodes, (res, el) => {
      if (el.tagName === 'SPAN') {
        res.push(el.childNodes[0]);
        return res;
      }
      return res;
    }, []);

    const arrayOfNative = _.reduce(childNodes, (res, el) => {
      if (el.tagName === 'SPAN') {
        const native = el.getAttribute('aria-label').split(',')[0];
        const { unified } = getEmojiDataFromNative(native, 'google', emojiData);
        res.push(unifiedEmojiToNative(unified));
        return res;
      }
      return res;
    }, []);

    const arrayOfStyles = _.reduce(arrayOfInnerSpans, (res, el) => {
      res.push(el.getAttribute('style'));
      return res;
    }, []);

    const emojiArr = [];

    for (let i = 0; i < arrayOfNative.length; i++) {
      const emoji = document.createElement('IMG');
      emoji.setAttribute('style', arrayOfStyles[i]);
      emoji.setAttribute('src', opacityImg);
      emoji.setAttribute('alt', arrayOfNative[i]);

      emojiArr.push(emoji.outerHTML);
    }

    msgToEdit = _.reduce(emojiArr, (init, el) =>
      // eslint-disable-next-line
       init.replace(/([$][#][\w\d\s]+[#][$])/, `${el}`),
    msgToEdit);

    dispatch({
      type: EDIT_MSG,
      payload: msgToEdit,
    });
    dispatch(closeMsgPopup());
  };
}

export function cancelEditMsg() {
  return ({
    type: CANCEL_EDIT_MSG,
  });
}

export function replyMsg(msgNode, emojiData) {
  return (dispatch, getState) => {
    const { childNodes } = msgNode;
    const { conversation: { fullMessages, selectedMsg } } = getState();
    let msgToReply = fullMessages.find(el => +el.id === +[...selectedMsg][0]).msg;

    const arrayOfInnerSpans = _.reduce(childNodes, (res, el) => {
      if (el.tagName === 'SPAN') {
        res.push(el.childNodes[0]);
        return res;
      }
      return res;
    }, []);

    const arrayOfNative = _.reduce(childNodes, (res, el) => {
      if (el.tagName === 'SPAN') {
        const native = el.getAttribute('aria-label').split(',')[0];
        const { unified } = getEmojiDataFromNative(native, 'google', emojiData);
        res.push(unifiedEmojiToNative(unified));
        return res;
      }
      return res;
    }, []);

    const arrayOfStyles = _.reduce(arrayOfInnerSpans, (res, el) => {
      res.push(el.getAttribute('style'));
      return res;
    }, []);

    const emojiArr = [];

    for (let i = 0; i < arrayOfNative.length; i++) {
      const emoji = document.createElement('IMG');
      emoji.setAttribute('style', arrayOfStyles[i]);
      emoji.setAttribute('src', opacityImg);
      emoji.setAttribute('alt', arrayOfNative[i]);

      emojiArr.push(emoji.outerHTML);
    }

    msgToReply = _.reduce(emojiArr, (init, el) =>
      // eslint-disable-next-line
       init.replace(/([$][#][\w\d\s]+[#][$])/, `${el}`),
    msgToReply);

    dispatch({
      type: REPLY_MSG,
      payload: msgToReply,
    });
    dispatch(closeMsgPopup());
  };
}

export function cancelReplyMsg() {
  return ({
    type: CANCEL_REPLY_MSG,
  });
}

export function resetConversationPage() {
  return ({
    type: RESET_CONVERSATION_PAGE,
  });
}

export function resetConversationPageLanguageSwitch() {
  return ({
    type: RESET_CONVERSATION_PAGE_LANGUAGE,
  });
}

export function startRecording() {
  return ({
    type: START_RECORDING,
  });
}

export function stopRecording(data) {
  return (dispatch) => {
    dispatch({
      type: STOP_RECORDING,
      payload: data,
    });
  };
}

export function removeVoice() {
  return ({
    type: REMOVE_VOICE,
  });
}

export function recordError() {
  return ({
    type: POPUP_OPEN,
    payload: {
      popupTitle: 'popup_error_title',
      popupMessage: 'popup_conversation_voice_error_message',
    },
  });
}

export function emojiButtonHandler() {
  return (dispatch, getState) => {
    const { conversation: { isEmojiFieldOpen } } = getState();

    dispatch({
      type: CHANGE_EMOJI_FIELD_STATE,
      payload: !isEmojiFieldOpen,
    });
  };
}

export function setLocationCoord(coords) {
  return ({
    type: SET_LOCATION_COORDS,
    payload: coords,
  });
}

export function addChatPhoto() {
  return (dispatch) => {
    const fileInput = document.querySelector('.conversation__photo-input');
    const photo = fileInput.files[0];
    const photoName = photo.name.toLowerCase();
    const isNormalSize = photo.size < MAX_SIZE && photo.size > MIN_SIZE;
    const isNormalType = FILE_TYPES.some(element => photoName.endsWith(element));

    if (!isNormalType || !isNormalSize) {
      fileInput.value = null;
      dispatch({
        type: ADD_CHAT_PHOTO + FAIL,
      });
      dispatch({
        type: POPUP_OPEN,
        payload: {
          popupTitle: 'popup_incorrect_file_title',
          popupMessage: 'popup_incorrect_file_message',
        },
      });
      return;
    }

    fileInput.value = null;

    dispatch(setPhotoType());
    dispatch({
      type: ADD_CHAT_PHOTO,
      payload: photo,
    });
  };
}

export function removeChatPhoto() {
  return ({
    type: REMOVE_CHAT_PHOTO,
  });
}

export function setLocationType() {
  return ({
    type: SET_LOCATION_TYPE,
  });
}

export function setTextType() {
  return ({
    type: SET_TEXT_TYPE,
  });
}

export function setPhotoType() {
  return ({
    type: SET_PHOTO_TYPE,
  });
}

export function sendMessage(inputNode) {
  return (dispatch, getState) => {
    const {
      conversation: {
        currentUserId,
        message,
        isMessageSend,
        msgType,
        audioData,
        coords,
        photoToLoad,
        editMode,
        selectedMsg,
        selectMsgId,
        replyMode,
      },
    } = getState();

    const max_length = 4096;
    const emojiNodeList = inputNode ? inputNode.querySelectorAll('img') : null;
    let requestAdress;
    const data = new FormData();
    let sendObj = {};
    const parentId = replyMode ? selectMsgId : '';
    const sendMsg = replaceEmojis(message, emojiNodeList).replace(/(<([^>]+)>)/ig, ' ');

    if (sendMsg.length > max_length) {
      dispatch({
        type: MAX_LENGTH_MESSAGE,
        payload: max_length,
      });
      return;
    }

    if (msgType === 'text') {
      if (editMode) {
        requestAdress = `${api.editMessage}?mid=${selectMsgId}`;
        sendObj = qs.stringify({
          thirdPerson_nick: currentUserId,
          t: sendMsg,
        });
      } else {
        requestAdress = api.postMessage;
        sendObj = qs.stringify({
          thirdPerson_nick: currentUserId,
          text: sendMsg,
          parent_id: parentId,
        });
      }
    }

    if (msgType === 'location') {
      requestAdress = `${api.postLocation}?nick=${currentUserId}&lat=${coords.lat}&lon=${coords.lon}&parent_id=${parentId}`;
      sendObj = {};
    }

    if (msgType === 'voice') {
      data.append('FILES', audioData);

      requestAdress = `${api.postVoice}?nick=${currentUserId}&parent_id=${parentId}`;
      sendObj = data;
    }

    if (msgType === 'photo') {
      data.append('FILES', photoToLoad, { type: 'image/jpeg' });
      data.append('caption', sendMsg);

      requestAdress = `${api.postPhoto}?nick=${currentUserId}&parent_id=${parentId}`;
      sendObj = data;
    }

    if (!isMessageSend) return;

    dispatch({
      type: START_SEND_MESSAGE,
    });

    Axios
      .post(requestAdress, sendObj, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: SEND_MESSAGE + SUCCESS,
          payload: data,
        });
        dispatch(scrollToBottom());
        if (editMode) {
          dispatch({
            type: EDIT_MSG + SUCCESS,
            payload: { id: selectMsgId, text: replaceEmojis(message, emojiNodeList) },
          });
        }
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SEND_MESSAGE + FAIL,
          payload: err,
        });
      });
  };
}

export function inputFocus() {
  return (dispatch) => {
    dispatch({
      type: FOCUS_INPUT,
    });
  };
}

export function inputBlur() {
  return (dispatch) => {
    dispatch({
      type: BLUR_INPUT,
    });
  };
}

export function inputChange(message) {
  return (dispatch) => {
    dispatch({
      type: CONVERSATION_INPUT_CHANGE,
      payload: {
        value: message,
      },
    });
  };
}

export function checkIsMessage(value) {
  return (dispatch) => {
    dispatch({
      type: CHECK_IS_MESSAGE,
      payload: !!value,
    });
  };
}

export function setCaretPosition(pos) {
  return (dispatch) => {
    dispatch({
      type: SET_CARET_POSITION,
      payload: pos,
    });
  };
}

export function clickEmoji(e, evt) {
  return (dispatch, getState) => {
    // evt.preventDefault();
    const { conversation: { message, caretPos } } = getState();
    const emojiToMessage = evt.currentTarget.childNodes[0];

    emojiToMessage.disable = 'true';
    const emoji = document.createElement('IMG');
    emoji.setAttribute('style', emojiToMessage.getAttribute('style'));
    emoji.setAttribute('src', opacityImg);

    dispatch({
      type: SCREEN_LOGGER,
      payload: caretPos,
    });

    const partBeforeCaret = message.substring(0, caretPos);
    const partAfterCaret = message.substring(caretPos);

    const emojiPic = unifiedEmojiToNative(e.unified);
    emoji.setAttribute('alt', emojiPic);

    dispatch({
      type: SET_CARET_POSITION,
      payload: caretPos + emoji.outerHTML.length + 2, // 2 пробела спереди и сзади
    });
    dispatch({
      type: ADD_EMOJI,
      payload: {
        value: `${partBeforeCaret} ${emoji.outerHTML} ${partAfterCaret}`,
      },
    });
  };
}

export function scrollToBottom() {
  return (dispatch) => {
    dispatch({
      type: SCROLL_TO_BOTTOM,
    });
  };
}

export function cancelScrollToBottom() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_SCROLL_TO_BOTTOM,
    });
  };
}
