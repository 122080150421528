import {
  FAIL,
  SUCCESS,
  START_MESSAGES_LOAD,
  MESSAGES_LOAD,
  RESET_MESSAGES_PAGE,
  GET_MESSAGE_FROM_STREAM,
} from '../constants';

const initialState = {
  isMessagesLoad: true,
  isFirstLoad: false,
  allDialogs: [],
  isError: false,
  messagesCnt: 0,
  currentPage: 0,
};

function addDialogs(allDialogs, currentDialogs) {
  if (currentDialogs) {
    currentDialogs.forEach(o => allDialogs.push(o));
  }
  return allDialogs;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_MESSAGES_LOAD:
      return {
        ...state,
        isMessagesLoad: false,
      };

    case MESSAGES_LOAD + SUCCESS:
      return {
        ...state,
        isFirstLoad: true,
        isMessagesLoad: true,
        allDialogs: addDialogs(state.allDialogs, action.payload.messages),
        messagesCnt: action.payload.cnt,
        currentPage: state.currentPage + 1,
      };

    case GET_MESSAGE_FROM_STREAM:
      return {
        ...state,
        allDialogs: state.allDialogs.map((item) => {
          if (item.personInfo.nick === action.nick) {
            return {
              ...item,
              msg: action.payload.msg,
              unreadCnt: ++item.unreadCnt,
            };
          }
          return item;
        }),
      };

    case MESSAGES_LOAD + FAIL:
      return {
        ...state,
        isMessagesLoad: true,
        isError: false,
      };

    case RESET_MESSAGES_PAGE:
      return {
        ...state,
        isMessagesLoad: true,
        isFirstLoad: false,
        allDialogs: [],
        isError: false,
        messagesCnt: 0,
        currentPage: 0,
      };

    default:
      return state;
  }
};
