import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Auth from './components/Authentication/Authentication';
import Reg from './components/Reg/Reg';
import Recovery from './components/Recovery/Recovery';
import Help from './components/Help/Help';
import Oferta from './components/Oferta/Oferta';
import Settings from './components/Settings/Settings';
import Profile from './components/Profile/Profile';
import Photos from './components/Photos/Photos';
import Messages from './components/Messages/Messages';
import User from './components/User/User';
import Conversation from './components/Conversation/Conversation';
import Loader from './components/Loader/Loader';

import './App.scss';

import { checkEvtStatus, resetEvtStatus, login, fetchFlowURL } from './actions/auth';
import changeLanguage from './actions/language';
import setLanguage from './util/setLanguage';
import { openStream } from './actions/conversation';

class App extends Component {
  static propTypes = {
    history: PropTypes.shape({
      listen: PropTypes.func,
    }),

    resetEvtStatus: PropTypes.func,
    checkEvtStatus: PropTypes.func,

    location: PropTypes.shape({
      pathname: PropTypes.string,
      split: PropTypes.func,
    }),

    auth: PropTypes.shape({
      isEventStatusLoad: PropTypes.bool,
    }),
  };

  // Из-за вызова экшонов на чек статуса при смене урла, был двойной рендер
  // компонентов по событию POP, вопрос, нужно ли вообще при каждой смене урла чекать статус

  // constructor(props) {
  //   super(props);
  //
  //   this.props.history.listen(() => {
  //     this.props.resetEvtStatus();
  //     this.props.checkEvtStatus();
  //   });
  // }

  componentDidMount() {
    const lan = Cookies.get('lan') || 'ru';

    this.props.changeLanguage(lan === 'ru' ? 'uz' : 'ru', false);
    this.props.login();
    this.props.checkEvtStatus();
    this.props.openStream();
    this.props.fetchFlowURL();
  }

  componentWillUnmount() {
    this.props.resetEvtStatus();
  }

  getTitle = () => {
    const { language } = this.props.language;

    switch (this.props.location.pathname.split('/')[1]) {
      case 'authentication':
        return 'Авторизация';
      case 'registration':
        return setLanguage(language, 'reg_title');
      case 'recovery':
        return 'Восстановление пароля';
      case 'help':
        return setLanguage(language, 'help_title');
      case 'conditions':
        return setLanguage(language, 'oferta_title');
      case 'settings':
        return setLanguage(language, 'settings_title');
      case 'profile':
        return setLanguage(language, 'profile_title');
      case 'photos':
        return setLanguage(language, 'profile_title');
      case 'messages':
        return setLanguage(language, 'messages_title');
      case 'user':
        return 'Страница пользователя';
      case 'conversation':
        return 'Диалог';
      default:
        return setLanguage(language, 'index_title');
    }
  };

  render() {
    const title = this.getTitle();

    const {
      isEventStatusLoad, isLoginLoad,
    } = this.props.auth;

    if (!isEventStatusLoad || !isLoginLoad) return <Loader />;

    return (
      <div className="wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Header title={title} />

        <Switch>
          <Route path="/authentication" component={Auth} />
          <Route path="/registration" component={Reg} />
          <Route path="/recovery" component={Recovery} />
          <Route path="/help" component={Help} />
          <Route path="/conditions" component={Oferta} />
          <Route path="/settings" component={Settings} />
          <Route path="/profile" component={Profile} />
          <Route path="/photos" component={Photos} />
          <Route path="/messages" component={Messages} />
          <Route path="/user/:uid" component={User} />
          <Route path="/conversation/:uid" component={Conversation} />
          <Route path="/" component={Main} />
        </Switch>
      </div>

    );
  }
}

const mapStateToProps = ({ auth, language }) => ({ auth, language });

const mapDispatchToProps = {
  checkEvtStatus,
  resetEvtStatus,
  login,
  changeLanguage,
  openStream,
  fetchFlowURL,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
