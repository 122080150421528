import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import { getEmojiDataFromNative, Emoji } from 'emoji-mart';
import reactStringReplace from 'react-string-replace';
import data from 'emoji-mart/data/all.json';
import setLanguage from '../util/setLanguage';
import agentLogo from '../images/agent-logo.png';
import { utf16toutf8 } from '../util/smileEncoder';

const clearEmoji = str => str.slice(2, -2);

const clearMsgFromOldEmoji = string => reactStringReplace(string, /([$][#][\w\d\s]+[#][$])/gm, (match, i) => {
  let emojiData = getEmojiDataFromNative(utf16toutf8(clearEmoji(match)), 'google', data);

  if (clearEmoji(match) === '2764') {
    emojiData = getEmojiDataFromNative(utf16toutf8('2764 FE0F'), 'google', data);
  }

  if (clearEmoji(match) === '263A') {
    emojiData = getEmojiDataFromNative(utf16toutf8('263A FE0F'), 'google', data);
  }

  if (emojiData === null) return <span />;

  return <Emoji key={match + i} sheetSize={32} emoji={emojiData} set="google" skin={emojiData.skin || 1} size={18} />;
});

const cleanMsg = string => string.replace(/&amp;nbsp;/g, '');

const parseLinkFromModerator = (string) => {
  if (string.match('&lt;a href=&quot;./oferta&quot;&gt;здесь&lt;/a&gt;.')) {
    return reactStringReplace(string, '&lt;a href=&quot;./oferta&quot;&gt;здесь&lt;/a&gt;.', (() => (
      <div key={uuidv1()}>
        <Link style={{ 'display': 'inline-block' }} to="/oferta"> здесь</Link>
        .
      </div>
    )));
  }

  if (string.match('&lt;a href=&quot;./oferta&quot;&gt;тут&lt;/a&gt;.')) {
    return reactStringReplace(string, '&lt;a href=&quot;./oferta&quot;&gt;тут&lt;/a&gt;.', (() => (
      <div key={uuidv1()}>
        <Link style={{ 'display': 'inline-block' }} to="/oferta"> тут</Link>
        .
      </div>
    )));
  }

  return string;
};

function MessagesList(options = [], language) {
  return _.map(options, option => (
    <div
      className="messages__message"
      key={option.personInfo.nick}
    >
      {(option.personInfo.nick === 'Moderator') && (
        <Link to={`/conversation/${option.personInfo.nick}`} className="messages__avatar messages__avatar--red-bg">
          <div className="messages__stretcher" />
          <img src={agentLogo} alt={option.personInfo.name} />
        </Link>
      )}

      {(option.personInfo.nick !== 'Moderator') && (
        <Link className={option.personInfo.online ? 'messages__avatar messages__avatar--online' : 'messages__avatar'} to={`/user/${option.personInfo.nick}`}>
          <div className="messages__stretcher" />
          <img src={option.personInfo.iurl} alt={option.personInfo.name} />
        </Link>
      )}

      <Link className="messages__body" to={`/conversation/${option.personInfo.nick}`}>
        <p className="messages__user-info">
          {(option.personInfo.nick === 'Moderator') ? setLanguage(language, 'moderator') : `${option.personInfo.name},`}
          &nbsp;
          {(option.personInfo.nick !== 'Moderator') && option.personInfo.age}
        </p>
        <p className="messages__text">
          {(option.type === 'message'
            || option.type === 'photolike'
            || option.type === 'photomoderate'
            || option.type === 'photodelete')
          && clearMsgFromOldEmoji(parseLinkFromModerator(cleanMsg(option.msg)))}

          {(option.type === 'voice') && <div>{setLanguage(language, 'voice_message')}</div>}
          {(option.type === 'photo') && <div>{setLanguage(language, 'photo_message')}</div>}
          {(option.type === 'location') && <div>{setLanguage(language, 'geo_message')}</div>}
        </p>
      </Link>
      {
        (option.unreadCnt > 0) && (<div className="messages__unread">{option.unreadCnt}</div>)
      }
    </div>
  ));
}

export default MessagesList;
