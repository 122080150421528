import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroller';

import './Messages.scss';

import MessagesList from '../MessagesList';
import Loader from '../Loader/Loader';

import loaderGif from '../../images/loader.gif';

import { getMessages, resetMessages } from '../../actions/messages';
import { getEvtSource, streamListener } from '../../actions/conversation';
import setLanguage from '../../util/setLanguage';

class Messages extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    messages: PropTypes.shape({
      allDialogs: PropTypes.array,
      isMessagesLoad: PropTypes.bool,
      isFirstLoad: PropTypes.bool,
      messagesCnt: PropTypes.number,
    }),

    getMessages: PropTypes.func,
    resetMessages: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onScrollMessages = debounce(400, this.onScrollMessages);
    this.messagesRef = React.createRef();
  }

  componentDidMount() {
    this.props.getMessages();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isStream } = this.props.conversation;
    const { allDialogs } = this.props.messages;
    const eventSource = getEvtSource();

    if (isStream && allDialogs.length) {
      for (let i = 0; i < allDialogs.length; i++) {
        eventSource.addEventListener(allDialogs[i].personInfo.nick, this.props.streamListener);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetMessages();
  }

  onScrollMessages = () => {
    const { isMessagesLoad, allDialogs } = this.props.messages;

    if (!isMessagesLoad) {
      return;
    }

    const scrollBottom = this.messagesRef.current.scrollTop
      + this.messagesRef.current.offsetHeight <= this.messagesRef.current.scrollHeight;

    if (scrollBottom) {
      this.props.getMessages(allDialogs.length);
    }
  };

  addMessages = (currentMessages) => {
    const { isMessagesLoad } = this.props.messages;

    if (isMessagesLoad) return currentMessages;

    return [];
  };

  render() {
    const { language } = this.props.language;
    const {
      allDialogs, isMessagesLoad, isFirstLoad, messagesCnt,
    } = this.props.messages;

    const { isLoggedIn } = this.props.auth;

    if (!isLoggedIn) return <Redirect to="/" />;

    if (!isFirstLoad) return <Loader />;

    const messagesList = MessagesList(allDialogs, language);
    const allMessagesCnt = allDialogs.length;

    if (messagesCnt === 0) {
      return (
        <div className="messages">
          <h2 className="messages__alert">{setLanguage(language, 'empty_messages_title')}</h2>
        </div>
      );
    }

    return (
      <div className="messages" onScroll={this.onScrollMessages} ref={this.messagesRef}>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.onScrollMessages}
          hasMore
          useWindow
        >
          <div key={0.1}>
            {messagesList}
          </div>
        </InfiniteScroll>

        {(!isMessagesLoad || (messagesCnt !== allMessagesCnt))
        && (
          <div className="messages__loader-field">
            {!isMessagesLoad && <img src={loaderGif} className="messages__loader" alt="Загрузка..." />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, messages, language, conversation }) => ({ auth, messages, language, conversation });

const mapDispatchToProps = {
  getMessages,
  resetMessages,
  streamListener
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
