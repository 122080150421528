import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import _ from 'lodash';

import './User.scss';
import '../Main/Main.scss';
import 'react-id-swiper/src/styles/scss/swiper.scss';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';
import NextIcon from '../Icons/NextIcon';
import LikeIcon from '../Icons/LikeIcon';
import MessageIcon from '../Icons/MessageIcon';
import UserCategories from '../UserCategories';

import loaderGif from '../../images/loader.svg';
import closeIcon from '../../images/close-main-profile.png';

import {
  loadUserInfo,
  resetUserPage,
  imgLoadHandler,
  openBlackListPopup,
  sendLike,
  setPhotosFromMain,
  getCurrentPhoto,
} from '../../actions/user';

import { closeUserProfilePopup } from '../../actions/userList';

class User extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }

  componentDidMount() {
    const {
      fromMain, setPhotosFromMain, photos, curPhotoIndex,
    } = this.props;
    const userId = (fromMain) ? this.props.userId : this.props.match.params.uid;

    if (fromMain) {
      setPhotosFromMain(photos, curPhotoIndex);
    }

    this.props.loadUserInfo(userId, this.props.fromMain);
  }

  componentWillUnmount() {
    this.props.resetUserPage();
  }

  checkIsCurrentPhotoLiked = (photoList, photoId) => _.find(photoList, el => el.photo_id === photoId).liked;

  renderPhotos = photos => _.map(photos, (photo, i) => {
    const {
      imgLoadHandler,
    } = this.props;

    return (
      <div className="user__slider-item" key={photo.photo_id} data-index={i}>
        <img className="user__photo swiper-lazy" src={photo.url} alt="" data-index={i} onLoad={imgLoadHandler} />
        <div className="swiper-lazy-preloader" />
      </div>
    );
  });

  sliderChangeHandler = () => {
    const {
      getCurrentPhoto,
    } = this.props;

    const activeSlide = this.slider.current.swiper.activeIndex;
    const prevSlide = this.slider.current.swiper.previousIndex;

    if (activeSlide === prevSlide) {
      return;
    }

    getCurrentPhoto(activeSlide);
  };

  render() {
    const {
      userView,
      userPhotoList,
      isUserInfoLoad,
      currentPhotoId,
      currentImgIndex,
      isBlocked,
    } = this.props.user;

    const {
      fromMain,
      imgLoadHandler,
      openBlackListPopup,
      closeUserProfilePopup,
      curPhotoIndex,
    } = this.props;

    if (!isUserInfoLoad && !fromMain) return <Loader />;
    const { isPopupOpen } = this.props.popup;

    const nick = (fromMain) ? this.props.userId : userView.nick;
    const name = (fromMain) ? this.props.name : userView.name;
    const age = (fromMain) ? this.props.age : userView.age;
    const city = (fromMain) ? this.props.city : userView.city;

    const photoList = (fromMain) ? this.props.photos : userPhotoList.urls_small;
    const hasPhoto = (fromMain) ? true : this.props.user.hasPhoto;

    const {
      book,
      film,
      sport,
      hobby,
      kitchen,
      music,
      foreign_lang: foreign,
      photo_url: defaultPhoto,
      online,
      greeting,
    } = userView;

    const numAge = (typeof age === 'string') ? +age.replace(/\D+/g, '') : age;
    const categories = [greeting, book, film, sport, hobby, kitchen, music, foreign];
    const convLinkUrl = `/conversation/${nick}`;

    const sliderParams = {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      initialSlide: fromMain ? curPhotoIndex : currentImgIndex,
      watchOverflow: true,
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        'init': () => false,
        'slideChange': () => this.sliderChangeHandler(),
      },
    };

    const { isLoggedIn } = this.props.auth;

    if (!isLoggedIn && !fromMain) return <Redirect to="/" />;

    if (isBlocked) return <Redirect to="/" />;

    const sliderPhotos = this.renderPhotos(photoList);

    return (
      <div className={fromMain ? 'user user--padding-bottom' : 'user'}>
        <div className="user__photo-container">
          {hasPhoto && (
            <Swiper {...sliderParams} ref={this.slider}>
              {sliderPhotos}
            </Swiper>
          )}

          {!hasPhoto && <img className="user__photo" src={defaultPhoto} alt={userView.name} onLoad={imgLoadHandler} />}

          {!fromMain && (
            <button
              type="button"
              className="user__icon user__icon--next"
              onClick={openBlackListPopup}
            >
              <NextIcon />
            </button>
          )}

          {!fromMain && hasPhoto && !this.checkIsCurrentPhotoLiked(photoList, currentPhotoId) && (
            <button
              type="button"
              onClick={this.props.sendLike}
              className="user__icon user__icon--like"
            >
              <LikeIcon />
            </button>
          )}

          {!fromMain && hasPhoto && this.checkIsCurrentPhotoLiked(photoList, currentPhotoId) && (
            <Link
              to={convLinkUrl}
              className="user__icon user__icon--like"
            >
              <MessageIcon />
            </Link>
          )}

          {!fromMain && !hasPhoto && (
            <Link
              to={convLinkUrl}
              className="user__icon user__icon--like"
            >
              <MessageIcon />
            </Link>
          )}
        </div>

        <div className="user__info">
          <p className={online ? 'user__name user__name--online' : 'user__name'}>
            {name}
            ,&nbsp;
            {numAge}
          </p>
          <p className="user__city">
            {city}
          </p>

          {fromMain && (
            <button className="button user__button-back" onClick={closeUserProfilePopup}>
              <img src={closeIcon} />
            </button>
          )}
        </div>

        <div className="user__categories">
          {fromMain && !isUserInfoLoad && <div className="swiper-lazy-preloader" />}
          {UserCategories(categories)}
        </div>

        {(isPopupOpen) && <Popup />}
      </div>
    );
  }

  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    user: PropTypes.shape({
      userView: PropTypes.shape({
        book: PropTypes.string,
        film: PropTypes.string,
        sport: PropTypes.string,
        hobby: PropTypes.string,
        kitchen: PropTypes.string,
        music: PropTypes.string,
        foreign: PropTypes.string,
        name: PropTypes.string,
        age: PropTypes.string,
        city: PropTypes.string,
        nick: PropTypes.string,
      }),
      isUserInfoLoad: PropTypes.bool,
      currentImg: PropTypes.string,
      currentImgIndex: PropTypes.number,
      photoCnt: PropTypes.number,
      isImgLoad: PropTypes.bool,
      isMutualLike: PropTypes.bool,
      isBlocked: PropTypes.bool,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    loadUserInfo: PropTypes.func,
    getNextPhoto: PropTypes.func,
    getPrevPhoto: PropTypes.func,
    resetUserPage: PropTypes.func,
    imgLoadHandler: PropTypes.func,
    openBlackListPopup: PropTypes.func,

    match: PropTypes.shape({
      params: PropTypes.shape({
        uid: PropTypes.string,
      }),
    }),
  };
}

const mapStateToProps = ({
  auth,
  user,
  popup,
  language,
}) => ({
  auth,
  user,
  popup,
  language,
});

const mapDispatchToProps = {
  loadUserInfo,
  resetUserPage,
  imgLoadHandler,
  openBlackListPopup,
  sendLike,
  closeUserProfilePopup,
  setPhotosFromMain,
  getCurrentPhoto,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
