export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

export const convertBase64ToImage = base64 => new Promise((resolve, reject) => {
  const img = new Image();
  img.onerror = reject;
  img.onload = () => {
    resolve(img);
  };
  img.src = base64;
});

export default function resetImageRotate(photo, srcOrientation) {
  return new Promise((resolve) => {
    convertBlobToBase64(photo)
      .then(base64 => convertBase64ToImage(base64))
      .then((img) => {
        const { width } = img;
        const { height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (srcOrientation > 4 && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        switch (srcOrientation) {
          case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, height, width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
          default: break;
        }

        ctx.drawImage(img, 0, 0);

        return (canvas.toDataURL('image/jpeg'));
      })
      .then(base64 => fetch(base64))
      .then(res => res.blob())
      .then(blob => new File([blob], 'ava.jpeg', blob))
      .then(file => resolve(file));
  });
}
