import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Photos.scss';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';
import SavePhotoIcon from '../Icons/SavePhotoIcon';
import DeletePhotoIcon from '../Icons/DeletePhotoIcon';

import {
  loadPhotosInfo,
  loadPhotoList,
  resetPhotoPage,
  changeActivePhoto,
  saveMainPhoto,
  deletePhoto,
  addPhoto,
  resetPhotoList,
} from '../../actions/photos';

import setLanguage from '../../util/setLanguage';

class Photos extends Component {
  static propTypes = {
    photos: PropTypes.shape({
      activePhoto: PropTypes.number,
      isViewShortLoad: PropTypes.bool,
      photoList: PropTypes.shape(),
      isPhotoListLoad: PropTypes.bool,
      isError: PropTypes.bool,
      mainImgUrl: PropTypes.string,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    loadPhotosInfo: PropTypes.func,
    loadPhotoList: PropTypes.func,
    resetPhotoPage: PropTypes.func,
    changeActivePhoto: PropTypes.func,
    saveMainPhoto: PropTypes.func,
    deletePhoto: PropTypes.func,
    addPhoto: PropTypes.func,
    resetPhotoList: PropTypes.func,
  };

  state = {
    fullPhotoList: [],
  };

  constructor(props) {
    super(props);
    this.onScrollPhotos = debounce(400, this.onScrollPhotos);
    this.photoDivRef = React.createRef();
  }

  componentDidMount() {
    this.props.loadPhotosInfo();
  }

  componentWillUnmount() {
    this.props.resetPhotoPage();
  }

  onScrollPhotos = () => {
    const { fullPhotoList } = this.props.photos;
    const scrollBottom = this.photoDivRef.current.scrollTop + this.photoDivRef.current.offsetHeight <= this.photoDivRef.current.scrollHeight;

    if (scrollBottom) {
      this.props.loadPhotoList(fullPhotoList.length);
    }
  };

  selectPhoto = (evt) => {
    if (evt.target.className === 'photos__item') {
      const currentPhotoId = evt.target.dataset.id;
      const currentPhotoUrl = evt.target.src;
      this.props.changeActivePhoto(currentPhotoId, currentPhotoUrl);
    }
  };

  renderPhotoList = options => options.map((option) => {
    const { activePhoto } = this.props.photos;

    return (
      <img
        className={(parseInt(activePhoto, 10) === option.photo_id)
          ? 'photos__item  photos__item--active' : 'photos__item'} src={option.url} alt={option.descr}
        key={option.photo_id} data-id={option.photo_id}
      />
    );
  });

  render() {
    const { language } = this.props.language;
    const { isPopupOpen } = this.props.popup;
    const { isLoggedIn } = this.props.auth;

    const {
      isViewShortLoad, isPhotoListLoad, isError, mainImgUrl, fullPhotoList
    } = this.props.photos;

    const {
      saveMainPhoto,
      deletePhoto,
      addPhoto,
      resetPhotoList,
    } = this.props;

    if (!isLoggedIn) return <Redirect to="/" />;

    if (!isViewShortLoad || !isPhotoListLoad) return <Loader />;

    if (isError) {
      return <Redirect to="/" />;
    }

    const imgToRender = this.renderPhotoList(fullPhotoList);

    return (
      <div className="photos" onScroll={this.onScrollPhotos} ref={this.photoDivRef}>
        <section className="photos__main-photo">
          <img className="photos__main-photo--img" src={mainImgUrl} alt="Главное фото" />

          <button className="photos__button photos__button--save" type="button" onClick={saveMainPhoto}>
            <SavePhotoIcon />
          </button>

          <button className="photos__button photos__button--delete" type="button" onClick={deletePhoto}>
            <DeletePhotoIcon />
          </button>

          <label className="photos__button photos__button--add" htmlFor="add-photo">
            <input className="photos__input" type="file" id="add-photo" name="add-photo" onChange={addPhoto} />
          </label>
        </section>

        <ul className="photos__list" onClick={evt => this.selectPhoto(evt)}>
          {imgToRender}
        </ul>

        <Link to="/profile" className="back-link">
          {setLanguage(language, 'photo_page_back_link')}
        </Link>
        {isPopupOpen && <Popup />}
      </div>
    );
  }
}

const mapStateToProps = ({
  photos,
  popup,
  auth,
  language,
}) => ({
  photos,
  popup,
  auth,
  language,
});

const mapDispatchToProps = {
  loadPhotosInfo,
  loadPhotoList,
  resetPhotoPage,
  changeActivePhoto,
  saveMainPhoto,
  deletePhoto,
  addPhoto,
  resetPhotoList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Photos));
