import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Settings.scss';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';

import setLanguage from '../../util/setLanguage';

import {
  logout,
  loadSettingsData,
  changeSmsSetting,
  saveSettingsData,
  openDeletePopup,
} from '../../actions/settings';

class Settings extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    settings: PropTypes.shape({
      smsSetting: PropTypes.string,
      isSettingsLoad: PropTypes.bool,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    logout: PropTypes.func,
    loadSettingsData: PropTypes.func,
    changeSmsSetting: PropTypes.func,
    saveSettingsData: PropTypes.func,
    openDeletePopup: PropTypes.func,
  };

  componentDidMount() {
    this.props.loadSettingsData();
  }

  render() {
    const { language } = this.props.language;
    const { isLoggedIn } = this.props.auth;
    const { smsSetting, isSettingsLoad } = this.props.settings;
    const {
      logout, changeSmsSetting, saveSettingsData, openDeletePopup,
    } = this.props;
    const { isPopupOpen } = this.props.popup;

    if (!isLoggedIn) {
      window.location = '/muloqot';
    }

    if (!isSettingsLoad) return <Loader />;

    return (
      <div className="settings">
        <form className="settings-form">
          <p className="settings-form__title">{setLanguage(language, 'settings_page_sms_setting_title')}</p>
          <div className="settings-form__checkbox-container">
            <label className="settings-form__check-label" htmlFor="range">
              <input
                type="radio"
                name="time"
                id="range"
                value="32752"
                checked={(smsSetting === '32752')}
                onChange={changeSmsSetting}
              />
              <span className="settings-form__checkbox" />
              {setLanguage(language, 'setting_page_sms_setting_first')}
            </label>
            <label className="settings-form__check-label" htmlFor="always">
              <input
                type="radio"
                name="time"
                id="always"
                value="16777215"
                checked={(smsSetting === '16777215')}
                onChange={changeSmsSetting}
              />
              <span className="settings-form__checkbox" />
              {setLanguage(language, 'setting_page_sms_setting_second')}
            </label>
            <label className="settings-form__check-label" htmlFor="never">
              <input
                type="radio"
                name="time"
                id="never"
                value="0"
                checked={(smsSetting === '0')}
                onChange={changeSmsSetting}
              />
              <span className="settings-form__checkbox" />
              {setLanguage(language, 'setting_page_sms_setting_third')}
            </label>
          </div>
          <button className="settings-form__button button button--red" type="button" onClick={saveSettingsData}>
            {setLanguage(language, 'setting_page_save_button')}
          </button>
        </form>
        <div className="settings__links">
          <button type="button" className="settings__link" onClick={openDeletePopup}>
            {setLanguage(language, 'setting_page_delete_profile')}
          </button>
          <button type="button" className="settings__link" onClick={logout}>
            {setLanguage(language, 'setting_page_logout')}
          </button>
          <Link to="/conditions" className="settings__link">
            {setLanguage(language, 'setting_page_help')}
          </Link>
        </div>

        {isPopupOpen && <Popup />}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  popup,
  language,
}) => ({
  auth,
  settings,
  popup,
  language,
});

const mapDispatchToProps = {
  logout,
  loadSettingsData,
  changeSmsSetting,
  saveSettingsData,
  openDeletePopup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
