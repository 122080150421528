import {
  SUCCESS,
  FAIL,
  HELP_SEND,
  START_HELP_LOAD,
  GET_CAPTCHA,
  HELP_INPUT_CHANGE,
  NOT_VALID_NUMBER,
  RESET_HELP_PAGE,
  SET_CAPTCHA_KEY,
} from '../constants';

const initialState = {
  isHelpLoad: true,
  captchaImg: '',
  name: '',
  email: '',
  phone: '',
  msg: '',
  captcha: '',
  errors: [],
  message: '',
  captchaKey: '',
  isInvalidPhone: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAPTCHA_KEY: {
      return { ...state, captchaKey: action.key };
    }
    case HELP_SEND + SUCCESS:
      const { errors, msg } = action.payload;

      if (errors.length !== 0 && msg === 'Not valid captcha') {
        return {
          ...state,
          message: 'help_error_captcha',
          isHelpLoad: true,
        };
      }

      return {
        ...state,
        isHelpLoad: true,
      };

    case HELP_SEND + FAIL:
      return {
        ...state,
        isHelpLoad: true,
      };

    case START_HELP_LOAD:
      return {
        ...state,
        message: '',
        isInvalidPhone: false,
        isHelpLoad: false,
      };

    case GET_CAPTCHA + SUCCESS:
      return {
        ...state,
        captchaImg: action.payload.msg,
        isHelpLoad: true,
      };

    case GET_CAPTCHA + FAIL:
      return {
        ...state,
        isHelpLoad: true,
      };

    case NOT_VALID_NUMBER:
      return {
        ...state,
        isInvalidPhone: true,
        message: 'help_error_number',
      };

    case HELP_INPUT_CHANGE:
      const { value, fieldName } = action.payload;

      return { ...state, [fieldName]: value, message: '' };

    case RESET_HELP_PAGE:
      return {
        ...state,
        isHelpLoad: true,
        captchaImg: '',
        name: '',
        email: '',
        phone: '',
        msg: '',
        captcha: '',
        errors: [],
        message: '',
      };

    default:
      return state;
  }
};
