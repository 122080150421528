import Axios from 'axios/index';
import qs from 'qs';

import {
  FAIL,
  SUCCESS,
  PHONE_SEND,
  REG_LOGIN_SEND,
  REG_INPUT_CHANGE,
  AUTH_INPUT_CHANGE,
  START_REG_SEND_LOAD,
  START_REG_LOGIN_LOAD,
  POPUP_OPEN,
  RESET_SUB_STATUS,
  FILE_TYPES,
  MAX_SIZE,
  MIN_SIZE,
  ADD_PHOTO,
  REG_PHOTO_LOAD,
  REG_DATA_SEND,
  START_REG_DATA_LOAD,
  SELECT_CHANGE,
} from '../constants';
import { api } from '../util';

export function sendPhone() {
  return (dispatch, getState) => {
    const { reg: { phone } } = getState();

    const data = new FormData();

    data.append('tel', `${phone.replace(/[^0-9]/g, '')}`);

    dispatch({
      type: START_REG_SEND_LOAD,
    });

    Axios
      .post(api.phoneInput, data, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: PHONE_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: PHONE_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function sendPass() {
  return (dispatch, getState) => {
    const { reg: { phone, pass } } = getState();

    dispatch({
      type: START_REG_LOGIN_LOAD,
    });

    Axios
      .post(api.login, qs.stringify({
        token: `7${phone.replace(/[^0-9]/g, '')}`,
        pass,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: REG_LOGIN_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: REG_LOGIN_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function openUnsubPopup() {
  return (dispatch) => {
    dispatch({
      type: POPUP_OPEN,
      payload: {
        popupTitle: 'popup_error_title',
        popupMessage: 'popup_error_message',
      },
    });
    dispatch({
      type: RESET_SUB_STATUS,
    });
  };
}

export function inputChange(evt) {
  return (dispatch) => {
    dispatch({
      type: REG_INPUT_CHANGE,
      payload: {
        value: evt.target.value,
        fieldName: evt.target.dataset.name,
      },
    });
    dispatch({
      type: AUTH_INPUT_CHANGE,
      payload: {
        value: evt.target.value,
        fieldName: evt.target.dataset.name,
      },
    });
  };
}

export function selectChange() {
  const fields = document.querySelectorAll('.reg-form__select');

  const fieldsObj = {};

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    fieldsObj[currentField.dataset.name] = currentField.value;
  }

  return ({
    type: SELECT_CHANGE,
    payload: fieldsObj,
  });
}

export function loadPhoto() {
  return (dispatch) => {
    const fileInput = document.querySelector('.photos__input');
    const photo = fileInput.files[0];
    const photoName = photo.name.toLowerCase();
    const isNormalSize = photo.size < MAX_SIZE && photo.size > MIN_SIZE;
    const isNormalType = FILE_TYPES.some(element => photoName.endsWith(element));

    if (!isNormalType) {
      fileInput.value = null;
      dispatch({
        type: ADD_PHOTO + FAIL,
      });
      dispatch({
        type: POPUP_OPEN,
        payload: {
          popupTitle: 'popup_incorrect_file_title',
          popupMessage: 'popup_incorrect_file_message',
        },
      });
      return;
    }

    if (!isNormalSize) {
      fileInput.value = null;
      dispatch({
        type: ADD_PHOTO + FAIL,
      });
      dispatch({
        type: POPUP_OPEN,
        payload: {
          popupTitle: 'popup_incorrect_file_size_title',
          popupMessage: 'popup_incorrect_file_size_message',
        },
      });
      return;
    }

    fileInput.value = null;

    dispatch({
      type: REG_PHOTO_LOAD,
      payload: photo,
    });
  };
}

export function submitRegData() {
  return (dispatch, getState) => {
    const { profile: { cityName, savingData } } = getState();
    const { reg: { regData } } = getState();
    const sexField = document.querySelector('input[id=men]');
    const sex = (sexField.checked) ? '1' : '2';

    const data = new FormData();

    data.append('name', regData.name);
    data.append('birthday_day', savingData.birthday_day);
    data.append('birthday_month', savingData.birthday_month);
    data.append('birthday_year', savingData.birthday_year);
    data.append('city_manual', cityName);
    data.append('sex', sex);
    data.append('FILES', regData.photo, regData.photo.name);

    dispatch({
      type: START_REG_DATA_LOAD,
    });

    console.log('---', data);

    Axios
      .post(api.registration, data, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: REG_DATA_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: REG_DATA_SEND + FAIL,
          payload: err,
        });
      });
  };
}
