import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ContentEditable from 'react-contenteditable';
import { Map, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import vmsg from 'vmsg/vmsg.es5';
import { Picker } from 'emoji-mart';
import Timer from 'react-compound-timer';

import reactStringReplace from 'react-string-replace';
import buttonGrey from '../images/input-text-gray.png';
import buttonRed from '../images/input-text.png';
// import microSvg from '../images/microphone.svg';
import SmilesIcon from './Icons/SmilesIcon';
import GeoIcon from './Icons/GeoIcon';
import GalleryIcon from './Icons/GalleryIcon';
import MicroIcon from './Icons/MicroIcon';
import CloseIcon from './Icons/CloseIcon';
import markerIcon from '../images/marker-icon.png';
import markerRetinaIcon from '../images/marker-icon-2x.png';
import markerShadow from '../images/marker-shadow.png';

import { convertBlobToBase64 } from '../util/resetImageRotate';

import {
  sendMessage,
  inputBlur,
  inputChange,
  inputFocus,
  setCaretPosition,
  startRecording,
  stopRecording,
  recordError,
  setLocationCoord,
  setLocationType,
  setTextType,
  emojiButtonHandler,
  clickEmoji,
  addChatPhoto,
  removeChatPhoto,
  removeVoice,
  cancelEditMsg,
  cancelReplyMsg,
} from '../actions/conversation';

import { MAX_VOICE_TIME } from '../constants';
import setLanguage from '../util/setLanguage';
import { utf16toutf8 } from '../util/smileEncoder';

const recorder = new vmsg.Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
  shimURL: 'https://unpkg.com/wasm-polyfill.js@0.2.0/wasm-polyfill.js',
});

const marker = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerRetinaIcon,
  iconAnchor: [5, 41],
  popupAnchor: [10, -44],
  iconSize: [25, 41],
  shadowUrl: markerShadow,
  shadowSize: [20, 36],
  shadowAnchor: [0, 36],
});

class ConversationMsgList extends Component {
  constructor() {
    super();
    this.contentEditable = React.createRef();
    this.refmarker = React.createRef();
    this.mapRef = React.createRef();
    this.startTimerRef = React.createRef();
    this.voiceTimer = null;
    this.state = {
      isVoicePopupOpen: false,
      isMapOpen: false,
      zoom: 15,
      center: {
        lat: 50.4475854,
        lng: 30.519837,
      },
      marker: {
        lat: 50.4475854,
        lng: 30.519837,
      },
      base64photo: null,
    };
  }

  componentDidMount() {
    // navigator.geolocation.getCurrentPosition(res => {
    //   this.setState({
    //     center: {
    //       lat: res.coords.latitude,
    //       lng: res.coords.longitude,
    //     },
    //     marker: {
    //       lat: res.coords.latitude,
    //       lng: res.coords.longitude,
    //     },
    //   });
    //   this.props.setLocationCoord(this.state.marker);
    // });
  }

  openVoicePopup = () => {
    this.setState({ isVoicePopupOpen: true });
  };

  closeVoicePopup = () => {
    this.setState({ isVoicePopupOpen: false });
  };

  closeVoicePopupHandler = () => {
    this.closeVoicePopup();
    this.props.removeVoice();
  };

  sendVoicePopupHandler = () => {
    this.closeVoicePopup();
    this.props.sendMessage();
  };

  startTimer = (start) => {
    start();
  };

  recorderHandler = async () => {
    const { isRecording } = this.props;

    if (isRecording) {
      if (this.state.isVoicePopupOpen) {
        const blob = await recorder.stopRecording();
        recorder.close();
        this.props.stopRecording(blob);
        clearTimeout(this.voiceTimer);
      } else {
        const blob = await recorder.stopRecording();
        recorder.close();
        this.props.stopRecording(blob);
        clearTimeout(this.voiceTimer);
        this.props.sendMessage();
      }
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.props.startRecording();
        await this.startTimerRef.current.click();
        this.voiceTimer = await setTimeout(() => { this.openVoicePopup(); this.recorderHandler(); }, MAX_VOICE_TIME);
      } catch (e) {
        console.error(e);
        this.props.recordError();
      }
    }
  };

  getCaretCharacterOffsetWithin = (element) => {
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection !== 'undefined') {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        const range = win.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type !== 'Control') {
      const textRange = sel.createRange();
      const preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }

    return caretOffset;
  };

  getHTMLCaretPosition = (element) => {
    const textPosition = this.getCaretCharacterOffsetWithin(element);
    const htmlContent = element.innerHTML;
    let textIndex = 0;
    let htmlIndex = 0;
    let insideHtml = false;
    const htmlBeginChars = ['<'];
    const htmlEndChars = ['>'];

    if (textPosition === 0) {
      return 0;
    }

    while (textIndex < textPosition) {
      htmlIndex++;

      while (htmlBeginChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
        insideHtml = true;

        while (insideHtml) {
          if (htmlEndChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
            if (htmlContent.charAt(htmlIndex) === ';') {
              htmlIndex--;
            }
            insideHtml = false;
          }
          htmlIndex++;
        }
      }
      textIndex++;
    }

    return htmlIndex;
  };

  onInputFocus = () => {
    this.props.inputFocus();
  };

  onInputBlur = () => {
    this.props.inputBlur();
    this.props.setCaretPosition(this.getHTMLCaretPosition(this.contentEditable.current));
  };

  onInputChange = () => {
    this.props.inputChange(this.contentEditable.current.innerHTML);
  };

  onMessageSend = () => {
    this.props.sendMessage(this.contentEditable.current);
  };

  resetGeoPosition = () => {
    this.setState({
      center: {
        lat: 50.4475854,
        lng: 30.519837,
      },
      marker: {
        lat: 50.4475854,
        lng: 30.519837,
      },
    });
    this.props.setLocationCoord(this.state.marker);

    navigator.geolocation.getCurrentPosition((res) => {
      this.setState({
        center: {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        },
        marker: {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        },
      });
      this.props.setLocationCoord(this.state.marker);
    });
  };

  geoButtonHandler = () => {
    this.setState(prevState => ({
      isMapOpen: !prevState.isMapOpen,
    }));
  };

  closeGeo = () => {
    this.setState({ isMapOpen: false });
    this.props.setTextType();
    this.resetGeoPosition();
  };

  sendGeo = () => {
    this.setState({ isMapOpen: false });
    this.props.setLocationType();
    this.props.sendMessage();
    this.resetGeoPosition();
  };

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        marker: marker.leafletElement.getLatLng(),
      });
    }
    this.props.setLocationCoord(this.state.marker);
  };

  mapHandleClick = (e) => {
    this.setState({
      zoom: this.mapRef.current.leafletElement._zoom,
      center: {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      },
      marker: {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      },
    });
    this.props.setLocationCoord(this.state.marker);
  };

  errorMessage = (language, message, maxLengthMsg) => {
    if (message === 'max_length_message') {
      return setLanguage(language, 'conversation_max_length_message') + maxLengthMsg;
    }

    return message;
  }

  render() {
    const {
      message, errorMessage, isEmojiFieldOpen, isRecording, photoToLoad, editMode, editedMsg, replyMode, repliedMsg, maxLengthMsg,
    } = this.props;
    const { language } = this.props.language;

    const {
      clickEmoji,
      emojiButtonHandler,
      addChatPhoto,
      removeChatPhoto,
      cancelEditMsg,
      cancelReplyMsg,
      isModerator,
    } = this.props;

    const { isMapOpen, zoom, isVoicePopupOpen } = this.state;

    const centerPosition = [this.state.center.lat, this.state.center.lng];
    const markerPosition = [this.state.marker.lat, this.state.marker.lng];

    if (photoToLoad) {
      convertBlobToBase64(photoToLoad).then((img) => {
        this.setState({ base64photo: img });
      });
    }

    return (
      <div className="conversation__input-box">
        <div className="conversation__left-side">
          <span className="conversation__error">
            { this.errorMessage(language, errorMessage, maxLengthMsg) }
          </span>
          <ContentEditable
            innerRef={this.contentEditable}
            html={message}
            className="conversation__input"
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
          />

          {!message && <div className="conversation__input-placeholder">{setLanguage(language, 'conversation_input_placeholder')}</div>}

          <div className="conversation__tools">
            <button type="button" className="conversation__emoji-btn" onClick={emojiButtonHandler}>
              <SmilesIcon />
            </button>
            {/*{!isModerator && <button type="button" className="conversation__emoji-btn" onClick={this.geoButtonHandler}>*/}
            {/*<GeoIcon />*/}
            {/*</button>}*/}
            {!isModerator && (
              <label className="conversation__emoji-btn" htmlFor="file">
                <input type="file" id="file" className="conversation__photo-input" onChange={addChatPhoto} />
                <GalleryIcon />
              </label>
            )}
          </div>
        </div>

        {photoToLoad
        && (
          <div className="conversation__photo-preview">
            <div className="conversation__photo-preview-container">
              <img src={this.state.base64photo} />
              <button onClick={removeChatPhoto} type="button" className="button conversation__btn-close-photo"><CloseIcon /></button>
            </div>
          </div>
        )
        }

        {isEmojiFieldOpen
        && (
          <Picker
            set="google"
            emojiSize={20}
            style={{ width: '175px' }}
            onSelect={this.props.onSelect}
            onClick={clickEmoji}
            sheetSize={32}
            skin={1}
            include={['people']}
          />
        )}

        {isVoicePopupOpen && (
          <div className="popup conversation__voice-popup">
            <div className="popup__dialog">
              <div className="popup__title">
                {setLanguage(language, 'conversation_voice_popup_title')}
              </div>
              <div className="popup__message">
                {setLanguage(language, 'conversation_voice_popup_msg')}
              </div>

              <div className="popup__extra-dialog popup__extra-dialog--flex-row">
                <button
                  className="popup__button popup__button--small button button--dark-grey"
                  type="button"
                  onClick={this.closeVoicePopupHandler}
                >
                  {setLanguage(language, 'conversation_voice_popup_cancel')}
                </button>
                <button
                  className="popup__button popup__button--small button button--red"
                  type="button"
                  onClick={this.sendVoicePopupHandler}
                >
                  {setLanguage(language, 'conversation_send')}
                </button>
              </div>
            </div>
          </div>
        )}

        {isMapOpen
        && (
          <div className="conversation__map-container conversation__map-container--absolute">
            <Map
              center={centerPosition}
              zoom={zoom}
              ref={this.mapRef}
              onClick={this.mapHandleClick}
            >
              <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                detectRetina
              />

              <Marker
                onDragend={this.updatePosition}
                position={markerPosition}
                icon={marker}
                draggable
                ref={this.refmarker}
              />
            </Map>

            <button onClick={this.closeGeo} type="button" className="button conversation__btn-close-map"><CloseIcon /></button>
            <button className="button button--red conversation__send-geo-btn" onClick={this.sendGeo}>{setLanguage(language, 'conversation_send')}</button>
          </div>
        )
        }
        {isMapOpen && <div className="conversation__overlay" />}

        {editMode && (
          <div className="conversation__edit-msg-preview">
            <div className="conversation__edit-msg" dangerouslySetInnerHTML={{ __html: editedMsg }} />
            <button type="button" className="button conversation__cancel-edit-btn" onClick={cancelEditMsg}><CloseIcon /></button>
          </div>
        )}

        {replyMode && (
          <div className="conversation__edit-msg-preview">
            <div className="conversation__edit-msg" dangerouslySetInnerHTML={{ __html: repliedMsg }} />
            <button type="button" className="button conversation__cancel-edit-btn" onClick={cancelReplyMsg}><CloseIcon /></button>
          </div>
        )}

        {isRecording
        && (
          <div className="conversation__voice-timer">
            <Timer
              formatValue={value => `${value < 10 ? `0${value}` : value}`}
              startImmediately={false}
            >
              {({ start }) => (
                <React.Fragment>
                  <div>
                    <Timer.Minutes />
                    :
                    <Timer.Seconds />
                  </div>
                  <div>
                    <button
                      hidden
                      ref={this.startTimerRef}
                      onClick={() => this.startTimer(start)}
                    >
                      Start
                    </button>
                  </div>
                </React.Fragment>
              )}
            </Timer>
          </div>
        )}

        {(!message && !photoToLoad && !isModerator) && (
          <button
            type="button"
            className={isRecording ? 'conversation__record conversation__record--active' : 'conversation__record'}
            onClick={this.recorderHandler}
          >
            <MicroIcon />
          </button>
        )}

        {!isModerator && (
          <button type="button" className="conversation__submit" onClick={this.onMessageSend} disabled={!message && !photoToLoad}>
            <img className="conversation__submit-img" style={{ display: 'none' }} src={buttonRed} alt="active" />
            {(message || photoToLoad) && <img className="conversation__submit-img" src={buttonRed} alt="active" />}
          </button>
        )}

        {isModerator && (
          <button type="button" className="conversation__submit" onClick={this.onMessageSend} disabled={!message && !photoToLoad}>
            {(!message) && <img className="conversation__submit-img" src={buttonGrey} alt="active" />}
            {(message) && <img className="conversation__submit-img" src={buttonRed} alt="active" />}
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    message, errorMessage, isEmojiFieldOpen, isRecording, photoToLoad, editMode, editedMsg, replyMode, repliedMsg, maxLengthMsg,
  } = state.conversation;

  return {
    photoToLoad,
    message,
    errorMessage,
    isEmojiFieldOpen,
    isRecording,
    editMode,
    editedMsg,
    replyMode,
    repliedMsg,
    language: state.language,
    maxLengthMsg,
  };
};

const mapDispatchToProps = {
  sendMessage,
  inputChange,
  inputFocus,
  inputBlur,
  setCaretPosition,
  clickEmoji,
  emojiButtonHandler,
  startRecording,
  stopRecording,
  recordError,
  removeVoice,
  setLocationCoord,
  addChatPhoto,
  setLocationType,
  setTextType,
  removeChatPhoto,
  cancelEditMsg,
  cancelReplyMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMsgList);
