import _ from 'lodash';

import {
  SUCCESS,
  FAIL,
  LOAD_USERS,
  GET_NEXT_USER,
  GET_PREV_USER,
  IMG_LOADED,
  SEARCH_DATA_SEND,
  FIRST_SEARCH_DATA_SEND,
  SET_USER_FROM_SLIDER,
  INCREASE_PAGE,
  REMOVE_USER_FROM_LIST,
  EXT_SEARCH_DATA_SEND,
  FIRST_EXT_SEARCH_DATA_SEND,
  OPEN_USER_PROFILE_POPUP,
  CLOSE_USER_PROFILE_POPUP,
  GET_USER_CURRENT_PHOTO,
} from '../constants';

const initialState = {
  isMainLoad: false,
  isFirstLoad: false,
  isLoad: false,
  isError: false,
  users: [],
  index: 0,
  page: 0,
  currentCount: null,
  isImgLoaded: false,
  isUserProfilePopupOpened: false,
};

const getNextPage = (prevPage, prevIndex, users) => {
  if (users) {
    const maxIndex = users.length - 2;
    return (prevIndex >= maxIndex) ? prevPage + 1 : prevPage;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS + SUCCESS:
      return {
        ...state,
        isLoad: true,
        isImgLoaded: true,
        users: state.users.concat(action.payload.users),
        isMainLoad: true,
        isFirstLoad: true,
      };

    case LOAD_USERS + FAIL:
      return { ...state, isError: true };

    case GET_NEXT_USER: {
      const nextPage = getNextPage(state.page, state.index, state.users);
      const nextIndex = action.payload.isRemove ? state.index : state.index + 1;

      return {
        ...state,
        index: nextIndex,
        page: nextPage,
        isLoad: (state.page === nextPage),
      };
    }

    case GET_PREV_USER:
      return {
        ...state,
        index: state.index - 1,
        isImgLoaded: false,
      };

    case REMOVE_USER_FROM_LIST:
      const arrToCut = action.payload.users;

      if (action.payload.currentUserId) {
        arrToCut.splice(_.findIndex(action.payload.users, el => el.nick === action.payload.currentUserId), 1);
      } else {
        arrToCut.splice(action.payload.index, 1);
      }

      return {
        ...state,
        users: arrToCut,
      };

    case SET_USER_FROM_SLIDER:
      return {
        ...state,
        index: action.payload,
      };

    case INCREASE_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };

    case FIRST_SEARCH_DATA_SEND:
      return {
        ...state,
        isLoad: false,
        index: 0,
        users: [],
        page: 0,
      };

    case FIRST_EXT_SEARCH_DATA_SEND:
      return {
        ...state,
        isLoad: false,
        index: 0,
        users: [],
        page: 0,
      };

    case SEARCH_DATA_SEND + SUCCESS:
      return {
        ...state,
        isSearchLoad: true,
        users: state.users.concat(action.payload.users),
        currentCount: state.currentCount + action.payload.users.length,
        isImgLoaded: true,
        isLoad: true,
      };

    case SEARCH_DATA_SEND + FAIL:
      return {
        ...state,
        isLoad: true,
        index: 0,
        isImgLoaded: true,
      };

    case EXT_SEARCH_DATA_SEND + SUCCESS:
      return {
        ...state,
        isSearchLoad: true,
        users: state.users.concat(action.payload.users),
        currentCount: state.currentCount + action.payload.users.length,
        isImgLoaded: true,
        isLoad: true,
      };

    case EXT_SEARCH_DATA_SEND + FAIL:
      return {
        ...state,
        isLoad: true,
        index: 0,
        isImgLoaded: true,
      };

    case IMG_LOADED:
      return { ...state, isImgLoaded: true };

    case GET_USER_CURRENT_PHOTO:
      state.users[state.index].photo_id = state.users[state.index].photos[action.payload].photo_id;

      return {
        ...state,
        users: [
          ...state.users,
        ],
      };

    case OPEN_USER_PROFILE_POPUP:
      return { ...state, isUserProfilePopupOpened: true };

    case CLOSE_USER_PROFILE_POPUP:
      return { ...state, isUserProfilePopupOpened: false };

    default:
      return state;
  }
};
