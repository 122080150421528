import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Map, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import uuidv1 from 'uuid/v1';
import { getEmojiDataFromNative, Emoji } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';
import reactStringReplace from 'react-string-replace';
import { debounce } from 'throttle-debounce';
import { utf16toutf8 } from '../util/smileEncoder';

import ConversationReplyIcon from './Icons/ConversationReplyIcon';
import ConversationRemoveSelectedIcon from './Icons/ConversationRemoveSelectedIcon';
import ConversationEditIcon from './Icons/ConversationEditIcon';
import ConversationDeleteIcon from './Icons/ConversationDeleteIcon';
import VoicePauseIcon from './Icons/VoicePauseIcon';
import VoicePlayIcon from './Icons/VoicePlayIcon';
import markerIcon from '../images/marker-icon.png';
import markerRetinaIcon from '../images/marker-icon-2x.png';
import markerShadow from '../images/marker-shadow.png';

import {
  openMsgMenu,
  closeMsgMenu,
  checkMsgOnSelectList,
  editMsg,
  removeMsg,
  openMsgPopup,
  closeMsgPopup,
  replyMsg,
} from '../actions/conversation';

const marker = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerRetinaIcon,
  iconAnchor: [5, 41],
  popupAnchor: [10, -44],
  iconSize: [25, 41],
  shadowUrl: markerShadow,
  shadowSize: [20, 36],
  shadowAnchor: [0, 36],
});

class ConversationMsgList extends Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.state = {
      isVoicePlaying: false,
      curVoice: '',
      curVoiceTime: 0,
      curVoiceDuration: 0,
      canChangeTrackTime: true,
    };
    this.clickTimeout = null;
    this.msgRefs = [];
  }

  componentDidMount() {
    this.voicesRef.addEventListener('ended', this.stopVoiceListener);
    this.voicesRef.addEventListener('timeupdate', this.playingVoiceListener);
    this.voicesRef.addEventListener('canplay', this.audioLoadListener);
  }

  componentWillUnmount() {
    this.voicesRef.removeEventListener('ended', this.stopVoiceListener);
    this.voicesRef.removeEventListener('timeupdate', this.playingVoiceListener);
    this.voicesRef.removeEventListener('canplay', this.audioLoadListener);
  }

  clearEmoji = str => str.slice(2, -2);

  clearMsgFromOldEmoji = (string) => {
    const strWithSpan = reactStringReplace(string, /([$][#][\w\d\s]+[#][$])/gm, (match, i) => {
      let emojiData = getEmojiDataFromNative(utf16toutf8(this.clearEmoji(match)), 'google', data);

      if (this.clearEmoji(match) === '2764') {
        emojiData = getEmojiDataFromNative(utf16toutf8('2764 FE0F'), 'google', data);
      }

      if (this.clearEmoji(match) === '263A') {
        emojiData = getEmojiDataFromNative(utf16toutf8('263A FE0F'), 'google', data);
      }

      if (emojiData === null) return <span />;

      return <Emoji key={match + i} sheetSize={32} emoji={emojiData} set="google" skin={emojiData.skin || 1} size={20} />;
    });

    return strWithSpan;
  };

  cleanMsg = string => string.replace(/&amp;nbsp;/g, '');

  parseLinkFromModerator = (string) => {
    if (string.match('&lt;a href=&quot;./oferta&quot;&gt;здесь&lt;/a&gt;.')) {
      return reactStringReplace(string, '&lt;a href=&quot;./oferta&quot;&gt;здесь&lt;/a&gt;.', (() => (
        <div key={uuidv1()}>
          <Link style={{ 'display': 'inline-block' }} to="/oferta"> здесь</Link>
          .
        </div>
      )));
    }

    if (string.match('<a href="./oferta">здесь</a>.')) {
      return reactStringReplace(string, '<a href="./oferta">здесь</a>.', (() => (
        <div key={uuidv1()}>
          <Link style={{ 'display': 'inline-block' }} to="/oferta"> здесь</Link>
          .
        </div>
      )));
    }

    if (string.match('&lt;a href=&quot;./oferta&quot;&gt;тут&lt;/a&gt;.')) {
      return reactStringReplace(string, '&lt;a href=&quot;./oferta&quot;&gt;тут&lt;/a&gt;.', (() => (
        <div key={uuidv1()}>
          <Link style={{ 'display': 'inline-block' }} to="/oferta"> тут</Link>
          .
        </div>
      )));
    }

    return string;
  };

  renderMessagePhotos = options => _.map(options, (option) => {
    if (option.url) {
      return (
        <div className="conversation__photo-box" key={option.url}>
          <img className="conversation__photo" src={option.url} alt="Фото" />
        </div>
      );
    }
    return '';
  });

  renderMessagePhoto = url => (
    <div className="conversation__photo-box" key={url}>
      <img className="conversation__photo" src={url} alt="Фото" />
    </div>
  );

  playButtonHandler = (evt) => {
    const isPaused = this.voicesRef.paused;
    const currentSrc = (this.voicesRef.src) ? new URL(this.voicesRef.src).pathname + new URL(this.voicesRef.src).search : '';
    const isOtherVoice = (currentSrc !== evt.target.dataset.voice);

    if (!isOtherVoice && !isPaused) {
      this.setState({ isVoicePlaying: false });
      this.voicesRef.pause();
      return;
    }

    this.setState({ isVoicePlaying: true, curVoice: evt.target.dataset.voice });
    if (isOtherVoice) {
      this.setState({ curVoiceTime: 0, curVoiceDuration: this.voicesRef.duration, canChangeTrackTime: false });
      this.voicesRef.src = evt.target.dataset.voice;
      clearInterval(this.timerId);
    }

    this.voicesRef.play();
  };

  audioLoadListener = () => {
    this.setState({ canChangeTrackTime: true });
  };

  stopVoiceListener = () => {
    this.voicesRef.pause();

    this.setState({ isVoicePlaying: false });
  };

  playingVoiceListener = () => {
    this.timerId = setInterval(() => {
      if (!this.voicesRef) return;
      this.setState({ curVoiceTime: this.voicesRef.currentTime, curVoiceDuration: this.voicesRef.duration });
    }, 0.5);
  };

  clickTrackHandler = (evt) => {
    if (!this.state.canChangeTrackTime) return;

    const rect = evt.target.getBoundingClientRect();
    const trackWidth = evt.target.offsetWidth;
    const x = evt.clientX - rect.left;
    const xPosPercent = (x * 100) / trackWidth;

    this.voicesRef.currentTime = (xPosPercent * this.voicesRef.duration) / 100;
  };

  renderMessageVoice = (msg, wave) => {
    const {
      curVoice, isVoicePlaying, curVoiceTime, curVoiceDuration,
    } = this.state;
    const isCurVoice = (curVoice === msg);

    const waveHTML = _.reduce(wave, (res, el) => {
      res += el.outerHTML;
      return res;
    }, '');
    const amountWidth = `${(curVoiceTime * 100) / curVoiceDuration}%`;

    if (msg) {
      return (
        <div className="conversation__audio-box">
          <button className="button conversation__audio-button" data-voice={msg} onClick={this.playButtonHandler}>
            {(isVoicePlaying && isCurVoice)
              ? <VoicePauseIcon />
              : <VoicePlayIcon />
            }
          </button>

          <div className="conversation__wave-container" onClick={isCurVoice ? this.clickTrackHandler : undefined}>
            <div className="conversation__wave-amount" style={isCurVoice ? { width: amountWidth } : {}}>
              <svg className="conversation__wave" dangerouslySetInnerHTML={{ __html: waveHTML }} />
            </div>
            <svg className="conversation__wave" dangerouslySetInnerHTML={{ __html: waveHTML }} />
          </div>

        </div>
      );
    }
  };

  renderGeo = (coords) => {
    if (coords) {
      const parsedCoords = JSON.parse(coords);

      return (
        <div className="conversation__map-container">
          <Map center={[parsedCoords.lat, parsedCoords.lon]} zoom={15}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              detectRetina
            />

            <Marker position={[parsedCoords.lat, parsedCoords.lon]} icon={marker} />
          </Map>
        </div>
      );
    }
  };

  endTouchMsgHandler = () => {
    clearTimeout(this.clickTimeout);
    return false;
  };

  startTouchMsgHandler = (evt) => {
    const { editMode, replyMode } = this.props;

    if (editMode || replyMode) return;

    const { selectedMsg, checkMsgOnSelectList, openMsgMenu } = this.props;
    const { id } = evt.currentTarget.dataset;

    if (selectedMsg.size < 1) {
      this.clickTimeout = setTimeout(() => {
        checkMsgOnSelectList(id);
        openMsgMenu();
      }, 500);

      return false;
    }
  };

  msgClickHandler = (evt) => {
    const { editMode, replyMode, isModerator } = this.props;
    const { id } = evt.currentTarget.dataset;

    if (isModerator) return;
    if (!evt.currentTarget.dataset.id) return;
    if (editMode || replyMode) return;
    if (evt.currentTarget !== evt.target && evt.currentTarget.dataset.msgtype !== 'message') return;

    const { selectedMsg, openMsgMenu, checkMsgOnSelectList } = this.props;

    if (selectedMsg.size < 1) {
      checkMsgOnSelectList(id);
      openMsgMenu();
    }

    if (selectedMsg.size > 0) {
      checkMsgOnSelectList(id);
    }
  };

  editMsgHandler = () => {
    const { editMsg, selectedMsg } = this.props;

    const msgId = [...selectedMsg][0];
    const msgNode = this.msgRefs.find(el => +el.dataset.id === +msgId);

    editMsg(msgNode, data);
  };

  replyMsgHandler = () => {
    const { replyMsg, selectedMsg } = this.props;

    const msgId = [...selectedMsg][0];
    const msgNode = this.msgRefs.find(el => +el.dataset.id === +msgId);

    replyMsg(msgNode, data);
  };

  checkMessage = (options, id) => {
    let changedMessage;
    options.map((option) => {
      if (id === option.id) {
        if (option.type === 'edited' || option.type === 'deleted') {
          changedMessage = option;
        }
      }
    });
    return changedMessage;
  };

  renderMessages = options => _.map(options, (option, i) => {
    const { selectedMsg } = this.props;
    let msg = (option.msg) ? this.clearMsgFromOldEmoji(this.parseLinkFromModerator(this.cleanMsg(option.msg))) : '';
    const quote = (option.msg) ? this.clearMsgFromOldEmoji(this.parseLinkFromModerator(this.cleanMsg(option.quote))) : '';
    const caption = (option.msg) ? this.clearMsgFromOldEmoji(this.parseLinkFromModerator(this.cleanMsg(option.caption))) : '';
    const change = this.checkMessage(options, option.id);
    if (change != null) {
      msg = (change.msg) ? this.clearMsgFromOldEmoji(this.parseLinkFromModerator(this.cleanMsg(change.msg))) : '';
    }

    if (
      option.type === 'message'
      || option.type === 'photolike'
      || option.type === 'photomoderate'
      || option.type === 'photodelete'
      || option.type === 'photo'
      || option.type === 'voice'
      || option.type === 'location'
    ) {
      if (option.fromMe) {
        return (
          this.renderMessageJsx(selectedMsg, option, i, quote, msg, caption, 'own')
        );
      }

      return (
        this.renderMessageJsx(selectedMsg, option, i, quote, msg, caption, 'visavi')
      );
    }
  });

  renderMessageJsx = (selectedMsg, option, i, quote, msg, caption, who) => (
    <div
      className={selectedMsg.has(option.id ? option.id.toString() : null)
        ? `conversation__message conversation__message--${who} conversation__message--selected `
        : `conversation__message conversation__message--${who}`
      }
      key={option.id}
      onTouchEnd={this.endTouchMsgHandler}
      // onTouchStart={this.startTouchMsgHandler}
      onClick={this.msgClickHandler}
      data-id={option.id}
      data-msgtype={option.type}
    >

      {
        who === 'own'
          ? (
            <div className="conversation__time">
              {option.dateTime}
            </div>
          ) : <div />
      }


      <div
        data-id={option.id}
        ref={(ref) => { this.msgRefs[i] = ref; return true; }}
        className={option.type === 'message'
          ? `conversation__text conversation__text--${who}`
          : `conversation__text conversation__text--${who} conversation__text--transparent`
        }
      >

        {option.quote
          && (
            <div className="conversation__quote">
              "
              {quote}
              "
            </div>
          )
        }

        {(option.type !== 'voice' && option.type !== 'location' && option.type !== 'photo')
          && <div>{msg}</div>
        }

        {(option.type === 'photo')
          && <div>{caption}</div>
        }

        {(option.type === 'photo') && this.renderMessagePhoto(option.msg)}
        {(option.type === 'voice') && this.renderMessageVoice(option.msg, option.wave)}
        {(option.type === 'location') && this.renderGeo(option.msg)}
        {this.renderMessagePhotos(option.photo_urls)}

      </div>
      {
        who === 'visavi'
          ? (
            <div className="conversation__time">
              {option.dateTime}
            </div>
          ) : <div />
      }
    </div>
  );

  render() {
    const {
      fullMessages,
      isMsgMenuOpen,
      closeMsgMenu,
      selectedMsg,
      removeMsg,
      isMsgPopupOpen,
      closeMsgPopup,
      isSelectEditable,
      isSelectReply,
    } = this.props;

    return (
      <div>
        <audio ref={ref => this.voicesRef = ref} preload="auto" />

        {isMsgMenuOpen && (selectedMsg.size > 0)
        && (
          <div
            className="conversation__msg-menu"
          >
            <button type="button" className="button conversation__msg-menu-btn" onClick={closeMsgMenu}>
              <ConversationRemoveSelectedIcon />
              {selectedMsg.size}
            </button>
            {(selectedMsg.size < 2 && isSelectReply) && <button type="button" className="button conversation__msg-menu-btn" onClick={this.replyMsgHandler}><ConversationReplyIcon /></button>}
            {(selectedMsg.size < 2 && isSelectEditable) && <button type="button" className="button conversation__msg-menu-btn" onClick={this.editMsgHandler}><ConversationEditIcon /></button>}
            <button type="button" className="button conversation__msg-menu-btn" onClick={removeMsg}><ConversationDeleteIcon /></button>
          </div>
        )
        }

        {/*{isMsgPopupOpen && (selectedMsg.size === 1) &&*/}
        {/*<div*/}
        {/*className="conversation__msg-popup"*/}
        {/*>*/}
        {/*{(selectedMsg.size < 2 && isSelectEditable) && <button type="button" className="button conversation__msg-popup-btn" onClick={this.replyMsgHandler}>Цитировать</button>}*/}
        {/*{(selectedMsg.size < 2 && isSelectEditable) && <button type="button" className="button conversation__msg-popup-btn" onClick={this.editMsgHandler}>Редактировать</button>}*/}
        {/*<button type="button" className="button conversation__msg-popup-btn" onClick={removeMsg}>Удалить</button>*/}
        {/*<button type="button" className="button conversation__msg-popup-btn" onClick={closeMsgPopup}>Отмена</button>*/}
        {/*</div>*/}
        {/*}*/}
        {/*{isMsgPopupOpen && (selectedMsg.size === 1) && <div className="conversation__overlay" onClick={closeMsgPopup} />}*/}

        {this.renderMessages(fullMessages)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    fullMessages, isMsgMenuOpen, selectedMsg, isMsgPopupOpen, isSelectEditable, editMode, replyMode, isSelectReply,
  } = state.conversation;
  return {
    fullMessages, isMsgMenuOpen, selectedMsg, isMsgPopupOpen, isSelectEditable, editMode, replyMode, isSelectReply,
  };
};

const mapDispatchToProps = {
  openMsgMenu,
  closeMsgMenu,
  checkMsgOnSelectList,
  editMsg,
  removeMsg,
  openMsgPopup,
  closeMsgPopup,
  replyMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMsgList);
